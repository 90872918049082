.comment-drawer {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  width: 30%;
  border-radius: 8px;
  height: 98%;

  .comments {
    border-bottom: 1px solid #f1f1f1;
  }

  .comment-title {
    height: 8%;
    border-bottom: 2px solid #f1f1f1;
  }

  .comment-list {
    overflow: auto;
  }

  .comment-section {
    height: 92%;
    overflow-y: auto;
    padding: 10px;
  }

  .comment_time {
    font-size: 12px;
    color: #9e9e9e;
  }

  .comment-wrapper {
    .popover {
      display: flex;
      flex-direction: column;
      padding: 15px;
      top: 100%;
      left: 100%;
      width: fit-content;
      transform: translate(-100%, 0);
      border-radius: 12px;
      box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      -webkit-backdrop-filter: blur(12px);
      backdrop-filter: blur(12px);
      background: rgba(255, 255, 255, 0.8);
    }
  }
}
