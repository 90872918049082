.multiSelect {
  .dropdown {
    &__select {
      background: #ffffff;
      // padding-left: 1rem;
      padding: 15px 1rem;
      align-items: center;
      margin-bottom: 0px;
    }
    cursor: pointer;
    border-bottom: 1px solid #d9d9d9;
  }
  .collapse {
    max-height: 0;
    overflow: hidden;
    transition: all 0.7s linear; /* Adjust the transition duration as needed */
  }

  .collapse.show {
    max-height: 1000px; /* Adjust the maximum height as needed */
  }

  .collapse input[type="search"],
  .collapse input[type="search"]:focus {
    padding: 8px 16px;
    display: inline-block;
    border: none;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: var(--island-bg-color);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
  }

  // .collapse {
  //   padding: 10px;
  // }
}
