.selectedCatagory {
  width: 16px;
  height: 16px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.itemtitle {
  display: flex;
  align-items: center;
  gap: 10px;
}
.selectedCatagoryLibraryatab {
  right: -9px;
  top: -6px;
  width: 16px;
  height: 16px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}