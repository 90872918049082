@import "../css/variables.module.scss";

.acv-hyperlinkContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  box-shadow: 0px 2px 4px 0 rgb(0 0 0 / 30%);
  z-index: var(--zIndex-hyperlinkContainer);
  background: var(--island-bg-color);
  border-radius: var(--border-radius-md);
  box-sizing: border-box;
  // to account for LS due to rendering icons after new link created
  min-height: 42px;

  &-input,
  button {
    z-index: 100;
  }

  &-input,
  &-link {
    height: 24px;
    padding: 0 8px;
    line-height: 24px;
    font-size: 0.9rem;
    font-weight: 500;
    font-family: var(--ui-font);
  }

  &-input {
    width: 18rem;
    border: none;
    background-color: transparent;
    color: var(--text-primary-color);

    outline: none;
    border: none;
    box-shadow: none !important;
  }

  &-link {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 15rem;
  }

  button {
    color: $oc-blue-6;
    background-color: transparent !important;
    font-weight: 500;
    &.acv-hyperlinkContainer--remove {
      color: $oc-red-9;
    }
  }

  &--remove .ToolIcon__icon svg {
    color: $oc-red-6;
  }

  .ToolIcon__icon {
    width: 2rem;
    height: 2rem;
  }

  &__buttons {
    flex: 0 0 auto;
  }
}
