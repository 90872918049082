#tags {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0 !important;
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 32px;
  width: 95%;
  border: 1px solid #767676;
  border-radius: 4px;
  margin: 8px 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  list-style: none;
  border-radius: 6px;
  margin: 3px;
  background: #494b83;
  color: #fff;
}

.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 10px;
  margin-left: 8px;
  color: #494b83;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  padding: 1px;
}

.tags-input input {
  flex: 1 1;
  border: none !important;
  height: 36px;
  font-size: 14px;
  padding: 0.6rem;
  margin: 0px !important;
}

.error {
  color: red !important;
  position: absolute !important;
  bottom: 25px !important;
  left: 25px !important;
  transform: translateY(100%) !important;
}
