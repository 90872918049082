.libraryItemDialog .previewDialog {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  //   box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  z-index: 1050 !important; /* Ensure it overlays other content */
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 50%;
  right: 2%;
  bottom: 2%;
  border-radius: 15px;
  .preview-dialog__header {
    display: flex;
    justify-content: space-between;
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 16px;
    height: 15%;
  }

  .preview-dialog__header__title {
    font-size: 1.25rem;
    font-weight: bold;
  }

  .preview-dialog__header__close {
    cursor: pointer;
    height: 15px;
    width: 15px;
  }

  .preview-dialog__content {
    flex: 1;
    overflow-y: auto;
    border: 1px solid lightgrey;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    height: 83%;
    .preview-container,
    .icons-div {
      overflow: visible;
    }
    position: relative;
  }

  .preview-image {
    height: auto;
    width: auto;
  }

  .preview-image-fullscreen {
    height: 100%;
    width: 100%;
  }
  .icons-div {
    display: flex;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .btn,
  .btn:hover {
    display: flex;
    gap: 10px;
    align-items: center;
    background: #494b83 !important;
    color: white !important;
    padding: 8px 12px;
    width: fit-content;
    border-radius: 8px;
  }
  .preview-dialog-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: 15%;
  }

  .preview-dialog-icons-fullscreen {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    position: relative;
  }

  .preview-dialog__content-fullscreen {
    flex: 1;
    overflow-y: auto;
    // border: 1px solid lightgrey;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
  }

  .preview-container {
    height: 100%;
    overflow: auto;
    background-color: #fff;
    margin: 0 16px;
    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }

  #preview-dialog-fullscreen {
    background-color: #fff;
  }
}

.sort-filter__button {
  background-color: #494b83 !important;
  color: white !important;
  border-radius: 8px;
  padding: 8px 12px;
  margin-right: 10px;
  height: 31px !important;
  font-size: 14px !important;
}
