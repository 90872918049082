.audio_div {
  audio {
    display: none;
  }

  .audio_title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
    padding-bottom: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .audio_delete {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    // padding-top: 5px;
    margin-bottom: 0;
    color: red;
    cursor: pointer;
  }

  .red-border {
    outline: 2px solid #c4314b;
  }

  .audio_card {
    // width: 178px;
    display: flex;
    flex-direction: column;
    height: 160px;
    position: relative !important;
    min-width: 0;

    .delete-icon-wrapper {
      width: 18px;
      height: auto;
    }
    .audio-name {
      text-overflow: ellipsis;
      overflow-x: clip;
      width: 100% !important;
      max-width: 100% !important;
    }
    @media screen And (min-width: 992px) {
      .audio-name {
        max-width: 180px;
      }
    }
    @media screen And (min-width: 768px) {
      .audio-name {
        max-width: 140px;
      }
    }

    .load_icon {
      // position: absolute;
      height: 1.5rem;
      width: 1.5rem;
      background-color: #585a96;
      border-radius: 50%;
      padding: 5px;
      // right: 13.5%;
      // top: -3%;
      cursor: pointer;
      margin-right: 5px;

      path {
        fill: #fff;
      }
    }
  }

  .custom_audio {
    cursor: pointer;
  }
}

@media (max-width: 1175px) {
  .card-width {
    width: 50%;
  }
}

.audio-model-width {
  .Modal__content {
    width: 528px !important;
    height: 320px !important;
    .Dialog__content {
      justify-content: flex-start !important;
    }
  }
  @media screen And (max-width: 992px) {
    .Modal__content {
      width: 528px !important;
      position: relative !important;
      margin: 6px;
      height: 371px !important;
      opacity: inherit !important;
      animation: none !important;
      .Dialog__content {
        padding: 16px 16px !important;
      }
    }
  }
  .ToolIcon__icon {
    display: none !important;
  }
  .start-timer {
    z-index: 99;
  }
  .timer-animation {
    box-shadow: 0px 0px 0px 8px rgba(73, 75, 131, 0.3);
    background-color: #494b83;
    color: #fff;
    width: 70px;
    height: 70px;
    animation: ease-in-out 1s infinite alternate;
    align-items: center;
  }
}

.audio-stop-model-width {
  .Modal__content {
    width: fit-content !important;
    height: 350px !important;
  }
  .ToolIcon__icon {
    display: none !important;
  }
  .start-timer {
    z-index: 99;
  }
  .timer-animation {
    box-shadow: 0px 0px 0px 8px rgba(73, 75, 131, 0.3);
    background-color: #494b83;
    color: #fff;
    width: 70px;
    height: 70px;
    animation: ease-in-out 1s infinite alternate;
    align-items: center;
  }

  @media screen And (max-width: 992px) {
    .Modal__content {
      width: 528px !important;
      position: relative !important;
      margin: 6px;
      height: 371px !important;
      opacity: inherit !important;
      animation: none !important;
      .Dialog__content {
        padding: 16px 16px !important;
      }
    }
  }
}

.load_icon_loader {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 4px;
  right: 31%;
  top: -3%;
}

.audio_container {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  .audio_dialog {
    margin-top: 30px;
    display: flex;
    position: relative;
    justify-content: center;

    audio {
      width: 50%;
    }
  }
  .close_icon {
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: #585a96;
    border-radius: 50%;
    padding: 4px;
    right: 8%;
    top: -3%;
    cursor: pointer;

    path {
      fill: #fff;
    }
  }
  .load_icon {
    // position: absolute;
    height: 20px;
    width: 20px;
    background-color: #585a96;
    border-radius: 50%;
    padding: 4px;
    // right: 30%;
    // top: -3%;
    cursor: pointer;

    path {
      fill: #fff;
    }
  }

  .uploaded_btn {
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    padding: 0;
    color: #585a96 !important;
    padding: 5px 15px !important;
    // background-color: #585a96 !important;
    cursor: pointer;
  }
  .uploaded_btn:hover {
    // background-color: #585a96 !important;
  }

  input[type="file"] {
    display: none;
  }

  .text {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: --popup-text-color;
  }
  .upload_btn {
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    padding: 0;
    color: #fff !important;
    padding: 5px 15px !important;
    background-color: #585a96 !important;
    cursor: pointer;
  }
  .upload_btn:hover {
    background-color: #585a96 !important;
  }
  .delete-icon-wrapper {
    width: 15px;
    height: auto;
  }
  .delete-icon-wrapper-1 {
    width: 20px;
    height: auto;
    flex-shrink: 0;
    z-index: 99;
  }
}
.notes_div {
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #e9ecef;
  margin-bottom: 20px;
  font-size: 12px;
}

.confirmation > svg > path {
  fill: #c92a2a !important;
}

.bg-color {
  background: #fce8c0;
  padding: 3px 3px 6px 3px;
  border-radius: 50%;
}
