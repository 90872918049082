.ui-outer-box {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.ui-image {
  max-width: 100px;
  border-radius: 4px;
  left: 20px;
  top: 20px;
  position: absolute;
}
.heading {
  font-size: 3em;
  margin: 0.67em 0;
  color: black;
  font-weight: bold;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.Karla {
  font-family: "Karla", sans-serif;
}
.para {
  font-size: 20px;
  text-align: left;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.bg-brand {
  background-color: #494b83 !important;
}
.svg-team {
  width: 2rem;
}
.ui-team-icon {
  padding-right: 3px;
}
.ui-button__content {
  align-self: center;
  font-size: 1rem;
  padding-left: 8px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.team-btn:hover {
  color: white;
  background-color: rgb(88, 90, 150);
}
.team-btn {
  color: white;
  display: flex;
  box-shadow: 0 0.3px 0.9px rgb(0 0 0 / 32%), 0 1.6px 3.6px rgb(0 0 0 / 28%);
  border-radius: 4px;
  height: 3rem;
  margin-bottom: 12px;
}

.rounded-lg {
  border-radius: 1rem;
}

.info-text {
  margin: 0;
  font-weight: 700;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  font-size: calc(1rem + 0.9vw);
  text-transform: uppercase;
}
.flexBox {
  display: flex;
  align-items: center;
}

.wb-logn{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}
.sl-board{
  background-color: #4262ff !important;
  font-size: 2rem;
  width: 15rem;
  height: 10rem;
}