/* Firefox-specific CSS */
@-moz-document url-prefix() {
  .spinner-play,
  .spinner-dialog * {
    scrollbar-width: none;
  }
}

.spinner-dialog-mobile {
  .Dialog__content {
    flex-direction: row !important;
    // padding: 0px !important;
    margin-top: 10px;
  }

  .Dialog__title {
    padding: 16px !important;
  }
}

.winner-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 580px;
  position: absolute;
  width: 100%;
}

.Dialog__title {
  padding: 12px;
}
.Dialog__content {
  overflow: auto;
}

.start-again-btn-isMobile {
  border: 1px solid #ccc;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
}

.start-again-btn button {
  // background-color: #494b83 !important;
  border: 1px solid #ccc;
  color: #000;
  border-radius: 5px;
  padding: 5px 15px !important;
  cursor: pointer;
}

.spinner-tabs {
  position: sticky !important;
  top: 0px;
  z-index: 1;
}

.spin_wheel_dialog {
  padding: 0 !important;
}

.spin_wheel_dialog .Modal__content {
  height: 100%;
}

// .acv .confirm-dialog--confirm.ToolIcon_type_button.confirm-dialog--save {
//   background-color: #494b83 !important;
// }
