.page,
.page-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--island-bg-color);
  position: relative;
  top: 32px;
  margin-bottom: 1rem;
}

.page-container:hover img {
  transform: scale(1.05); /* Slight zoom on hover for a subtle effect */
}

.page-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  color: #000;
  background-color: rgba(206, 212, 218, 0.5); /* Transparent gray overlay */
  opacity: 0; /* Start invisible */
  transition: opacity 0.3s ease; /* Smooth transition */
  pointer-events: none; /* Ensures overlay doesn't block interactions */
  border-radius: 4px;
}

.page-container {
  padding: 10px;
}

.page-container:hover::after {
  opacity: 1; /* Display overlay on hover */
  padding: 5px 5px 3px 5px;
}

.librarySidebar {
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.min-w-170 {
  min-width: 170px;
}

.sidebar-container::-webkit-scrollbar {
  display: none;
}

.page {
  padding-bottom: 12px;
  border-bottom: 1px solid #00000026;
  white-space: nowrap;
}

.btn-compact {
  margin: 0 auto 0 !important;
  background-color: white !important;
}
.nameIcon {
  .text {
    font-size: 14px;
    line-height: 20px;
  }
  .icons {
    width: 38px;
    height: 16px;
    display: flex;
    justify-content: space-between;
  }
}
.pagePreview {
  width: 186px;
  height: 80px;
  background: var(--button-gray-1);
  box-shadow: 0px 0.15px 0.45px rgb(0 0 0 / 11%),
    0px 0.8px 1.8px rgb(0 0 0 / 13%);
  border-radius: 4px;
  cursor: pointer;
}

.nameIcon {
  display: flex;
  justify-content: space-between;
  width: 186px;
  height: 16px;
  // margin: 6px 0px;
  margin: 10px 0px;
}

.btnNewPage {
  margin: auto !important;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 16px;
}

button.ToolIcon_type_button.ToolIcon_size_medium.cancel-page-dialogBtn.ToolIcon_type_button--show.ToolIcon {
  // margin-left: 15px;
  // color: #000 !important;
  background-color: rgb(68, 67, 63);
}
button.ToolIcon_type_button.ToolIcon_size_medium.Remove-page-dialogBtn.ToolIcon_type_button--show.ToolIcon {
  // margin-left: 15px;
  color: #000;
  background-color: red;
}
