.group-wrapper {
  border: 1px solid hsl(0, 0%, 74%);
  border-radius: 6px;
  .group-name {
    margin: 0 48px;
  }
  .group-page-wrapper {
    height: 45px;
    .group-page {
      width: 100px;
      height: 100%;
      border: 1px solid hsl(0, 0%, 74%);
      border-radius: 5px;
      .square-box-icon-outside {
        width: 25px;
      }
    }
  }
  .edit-icon {
    margin-right: 16px;
  }

  @media screen And (max-width: 480px) {
    .edit-icon {
      margin-right: 6px;
    }
    .group-page-wrapper {
      display: none !important;
    }
    .group-name {
      margin: 0 15px;
      font-size: 15px !important;
    }
  }
  @media screen And (max-width: 576px) {
    .group-name {
      margin: 0 20px;
    }
  }
}
.open-group-wrapper {
  border: 1px solid hsl(0, 0%, 74%);
  border-radius: 6px;
  .left-section {
    border-right: 1px solid hsl(0, 0%, 74%);
    @media screen and (max-width: 768px) {
      border-right: 0 !important;
      border-bottom: 1px solid hsl(0, 0%, 74%);
    }
    .lower-block {
      .students-list {
        max-height: 185px;
        min-height: 185px;
        overflow: auto;
      }
    }
  }
  .right-section {
    .group-pages-wrapper {
      max-height: 280px;
      // min-height: 280px;
      min-height: 324px;
    }
    .square-box {
      height: 150px;
      width: 150px;
      border: 1px solid hsl(0, 0%, 74%);
      border-radius: 30px;
      .square-box-icon {
        width: 30px;
      }
    }
  }
}
.group-icon-left {
  width: 27px;
}
.group-icon-right {
  width: 22px;
}
@media screen And (max-width: 480px) {
  .group-icon-left {
    width: 23px;
  }
  .group-icon-right {
    width: 21px;
  }
}
