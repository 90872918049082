@import "../css/variables.module";

.acv {
  .Card {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 290px;

    margin: 1em;

    text-align: center;

    .Card-icon {
      font-size: 2.6em;
      display: flex;
      flex: 0 0 auto;
      padding: 1.4rem;
      margin-bottom: 1.4rem;
      border-radius: 50%;
      background: var(--card-color);
      color: #6264a7;
      background: #e9eaf6;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .Card-details {
      font-size: 0.96em;
      min-height: 90px;
      padding: 0 1em;
      margin-bottom: auto;
    }

    & .Card-button.ToolIcon_type_button {
      height: 2.5rem;
      margin-top: 1em;
      margin-bottom: 0.3em;
      background-color: #6264a7;
      &:hover {
        background-color: #585a9b;
      }
      &:active {
        background-color: #45477e;
      }
      .ToolIcon__label {
        color: $oc-white;
      }
    }
  }
}
