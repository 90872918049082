.preview-dialog-div {
  display: flex;
  align-items: center;
}

.loadButton {
  width: 15% !important;
  height: 34px !important;
  background: #4b4984 !important;
  justify-content: flex-start !important;
}

.load_icon {
  width: 1rem;
  height: 1rem;
}

.uploadButton {
  color: white;
  font-size: 16px;
  margin-right: 9px;
}

.preview-heading{
    width: 100%;
}