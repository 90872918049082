.spinner-btn {
  display: block;
  margin: 0 auto;
  background-color: #494b83 !important;
  color: #fff;
  padding: 0 30px !important;
}

.spin-btns {
  .spin-btn {
    display: block;
    color: #a69f9f;
    border: 1px solid #a69f9f;
    margin: 5px;
    height: fit-content;
  }
}

#spinner-root {
  max-height: 480px !important;
}

.wheel-wrap {
  border: 2px solid #e8e8e8;
  border-radius: 4px;
}

.imageGenerator {
  gap: 12px;
}

.imageGenerator::-webkit-scrollbar {
  display: none;
}

.spin-save-div {
  bottom: 10px;
  right: 30px;
}

.spin-save {
  margin: 0 auto;
  display: block;
}

.spin-save .icon {
  width: 20px;
}

.ispublic-checkbox .Checkbox-box {
  margin-left: 0 !important;
  box-shadow: 0 0 0 2px #494b83 !important;
}

.ispublic-checkbox.is-checked .Checkbox-box {
  background-color: #494b83 !important;
  color: #fff !important;
  box-shadow: 0 0 0 2px #494b83 !important;
}

.minimizeIcon .icon {
  background-color: #d6d6d6ab;
  height: 34px;
  width: 34px;
  border-radius: 100%;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0;
}

@media screen and (max-width: 992px) {
  .spin-save-div {
    // position: absolute !important;
    bottom: 8px;
    left: 10px;
  }
}
