.bottom-menu-section-wrapper {
  position: absolute;
  bottom: 20px;
  transform: translate(50%, 0);
  right: 50%;
}

.bottom-menu-section-wrapper .bottom-menu-section {
  max-width: 90vw;
  /* overflow: auto; */
}

.more_icon {
  rotate: 90deg;
}

.action_icon_wrapper {
  font-size: 15;
}

.active_frame_border_color {
  border: 1px solid #80bbff !important;
}
