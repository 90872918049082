.video_container {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  .upload_btn {
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    padding: 0;
    color: #fff !important;
    padding: 5px 15px !important;
    background-color: #585a96 !important;
    cursor: pointer;
  }
  .upload_btn:hover {
    background-color: #585a96 !important;
  }
  .delete-icon-wrapper {
    width: 15px;
    height: auto;
  }
  .video-name {
    text-overflow: ellipsis;
    overflow-x: clip;
    width: 100% !important;
    max-width: 100% !important;
  }
  .delete-icon-wrapper-1 {
    width: 24px;
    height: auto;
    flex-shrink: 0;
    z-index: 99;
  }
  .btn_div {
    margin-top: auto;
    margin-bottom: auto;
  }
  .uploaded_btn {
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    padding: 0;
    color: #585a96 !important;
    padding: 5px 15px !important;
    // background-color: #585a96 !important;
    cursor: pointer;
    height: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .uploaded__btn {
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    padding: 0;
    color: rgb(196, 49, 75) !important;
    padding: 5px 15px !important;
    height: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #585a96 !important;
    cursor: pointer;
  }
  .uploaded_btn:hover {
    // background-color: #585a96 !important;
  }
  .video_card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    // width: 270px;
    height: 337px;
    // height: 40% !important;
    cursor: pointer;
    justify-content: center;
    min-width: 0;

    .video-wrapper {
      max-height: 78%;
    }
  }

  .video_card video {
    // object-fit: contain;
    // max-width: 100%;
    // max-height: 100%;
    width: auto;
    // height: auto;
    height: 100%;
  }

  input[type="file"] {
    display: none;
  }
  .text {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: --popup-text-color;
  }

  .video-name {
    text-overflow: ellipsis;
    overflow-x: clip;
    width: 100% !important;
    max-width: 100% !important;
  }

  @media screen And (min-width: 992px) {
    .video-name {
      max-width: 180px;
    }
  }
  @media screen And (min-width: 768px) {
    .video-name {
      max-width: 140px;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: 0.5s ease;
  }

  .video-tag:hover .overlay {
    display: block;
    background: rgba(0, 0, 0, 0.2);
  }

  .video_icon {
    position: absolute;
    height: 70px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.4s ease;
    z-index: 1;
    width: 88%;
    top: 50%;
    left: 6%;
    padding: 5px !important;
  }

  .video_card:hover .video_icon {
    opacity: 1;
    text-align: center;
  }

  .video_card:hover .previewBtn {
    background: rgb(75, 73, 132);
  }

  .video_dialog {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    // overflow: auto;
    video {
      width: 50%;
    }
  }

  .previewBtn {
    border-radius: 100px;
    background: rgb(75, 73, 132);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
    padding-left: 15px;
    padding-right: 15px;
    left: 36%;
    position: absolute;
  }

  .previewText {
    color: var(--Brand-Primary-Parchment, #faf3ec);

    /* 12/Demibold All Caps */
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0px;
    margin: 0px;
    font-size: 16px;
  }

  .close_icon {
    // position: absolute;
    height: 1.2rem;
    width: 1.2rem;
    background-color: rgb(196 49 75);
    border-radius: 50%;
    padding: 5px;
    // right: 300px;
    // top: 102px;
    cursor: pointer;
    margin-right: 5px;

    path {
      fill: #fff;
    }
  }
  .load_icon {
    // position: absolute;
    height: 1.2rem;
    width: 1.2rem;
    background-color: #585a96;
    border-radius: 50%;
    padding: 5px;
    // right: 322px;
    // top: 102px;
    cursor: pointer;
    margin-right: 5px;

    path {
      fill: #fff;
    }
  }
  .load_icon_loader {
    position: absolute;
    height: 20px;
    width: 20px;
    // background-color: #585a96;
    border-radius: 50%;
    padding: 4px;
    right: 322px;
    top: -4px;
  }
  .red-border {
    outline: 2px solid #c4314b;
  }
}

.video-model-width {
  .Modal__content {
    width: 528px !important;
  }

  @media screen And (max-width: 992px) {
    .Modal__content {
      width: 528px !important;
      position: relative !important;
      .Dialog__content {
        padding: 16px 16px !important;
      }
    }
  }

  video {
    width: auto;
    filter: drop-shadow(rgba(0, 0, 0, 0.16) 0px 1px 4px);
    max-height: 300px !important;
  }

  .ToolIcon__icon {
    display: none !important;
  }
}

.notes_div {
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #e9ecef;
  margin-bottom: 20px;
  font-size: 12px;
}

.bg-color {
  background: #fce8c0;
  padding: 3px 3px 6px 3px;
  border-radius: 50%;
}
