.result-wrapper {
  height: 250px;
  /* overflow: hidden; */
}

.celebrartion-wrapper {
  bottom: 30%;
}

.result-dialog {
  .Dialog__title {
    padding: 16px !important;
  }
}

.btn-sw-remove {
  background-color: #d94747 !important;
  color: white;
  z-index: 1;
}

.btn_close {
  background-color: gray !important;
  color: white;
  z-index: 1;
}

.btn-sw-remove:hover,
.btn_close:hover {
  color: white !important;
}

.btn-sw-remove:hover {
  background-color: #a02121 !important;
}

.btn_close:hover {
  background-color: rgb(90, 90, 90) !important;
}
