@import "open-color/open-color.scss";

@mixin isMobile() {
  @at-root .acv--mobile#{&} {
    @content;
  }
}

$theme-filter: "invert(93%) hue-rotate(180deg)";

:export {
  themeFilter: unquote($theme-filter);
}

@mixin outlineButtonStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem;
  width: var(--button-width, var(--default-button-size));
  height: var(--button-height, var(--default-button-size));
  box-sizing: border-box;
  // border-width: 1px;
  // border-style: solid;
  // border-color: var(--button-border, var(--default-border-color));
  // border-radius: var(--border-radius-lg);
  cursor: pointer;
  background-color: var(--button-bg, var(--island-bg-color));
  color: var(--button-color, var(--color-on-surface));
  font-family: var(--ui-font);

  svg {
    width: var(--button-width, var(--lg-icon-size));
    height: var(--button-height, var(--lg-icon-size));
  }

  &:hover {
    background-color: var(--button-hover-bg, var(--island-bg-color));
    border-color: var(
      --button-hover-border,
      var(--button-border, var(--default-border-color))
    );
    color: var(
      --button-hover-color,
      var(--button-color, var(--text-primary-color, inherit))
    );
  }

  &:active {
    background-color: var(--button-active-bg, var(--island-bg-color));
    border-color: var(--button-active-border, var(--color-primary-darkest));
  }

  &.active {
    background-color: var(
      --button-selected-bg,
      var(--color-surface-primary-container)
    );
    border-color: var(
      --button-selected-border,
      var(--color-surface-primary-container)
    );

    &:hover {
      background-color: var(
        --button-selected-hover-bg,
        var(--color-surface-primary-container)
      );
    }

    svg {
      color: var(--button-color, var(--color-on-primary-container));
    }
  }
}
