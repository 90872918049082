// /* TiptapEditor.scss */
.tiptap-editor {
  position: relative;

  &.has-focus {
    .placeholder {
      display: none;
    }
  }

  .react-resizable-handle {
  }

  .placeholder {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #999;
    pointer-events: none;
  }

  .editor-wrapper {
    border: 2px dashed #b2b2b2;

    #tiptap-container {
      .tiptap {
        &:focus-visible {
          outline: none;
        }
        &:focus {
          outline: none;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }
  .font-family {
    width: 100px;
    z-index: 99;
    .preview {
      width: 100%;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .font-type-list {
      // top: 50%;
      width: "135px";
      left: 50%;
      height: 500px;
      overflow-y: auto;
    }
  }
  .font-size {
    .preview {
      border: 0;
      width: fit-content;
      text-align: center;
      max-width: 80px;
      height: 100%;
    }
  }
  .font-highlight,
  .font-color {
    .current-color-preview {
      width: 26px;
      height: 6px;
      border: 0;
    }
  }
  .font-highlight {
  }
  .tiptap.ProseMirror {
    p {
      word-break: break-all;
    }
  }
}
