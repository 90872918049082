@import "open-color/open-color.scss";
@import "../css/variables.module";

.acv {
  .ToolIcon {
    display: inline-flex;
    align-items: center;
    position: relative;
    font-family: Cascadia;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    border-radius: var(--space-factor);
    user-select: none;
    justify-content: center;

    // background-color: var(--button-gray-1);

    &:hover {
      background-color: var(--button-gray-2);
    }
    &:active {
      background-color: var(--button-gray-3);
    }
  }

  .ToolIcon--plain {
    background-color: transparent;
    .ToolIcon__icon {
      // width: 2rem;
      // height: 2rem;
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .ToolIcon__icon {
    width: 2.5rem;
    height: 2.5rem;
    color: var(--icon-fill-color);

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: var(--space-factor);

    svg {
      position: relative;
      height: 1.3em;
      // fill: var(--icon-fill-color);
      color: var(--icon-fill-color);
    }

    & + .ToolIcon__label {
      margin-inline-start: 0;
    }
  }

  .ToolIcon__label {
    color: var(--icon-fill-color);
    font-family: var(--ui-font);
    margin: 0 0.8em;
    text-overflow: ellipsis;
  }

  .ToolIcon_size_small .ToolIcon__icon {
    // width: 2rem;
    // height: 2rem;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.8em;
  }

  .acv .ToolIcon_type_button,
  .Modal .ToolIcon_type_button,
  .ToolIcon_type_button {
    padding: 0;
    border: none;
    margin: 0;
    font-size: inherit;

    &:focus {
      box-shadow: 0 0 0 2px var(--focus-highlight-color);
    }

    &.ToolIcon--selected {
      // background-color: var(--button-gray-2);

      &:active {
        background-color: var(--button-gray-3);
      }
    }

    &:hover {
      // background-color: var(--button-gray-2);
    }

    &:active {
      background-color: var(--button-gray-3);
    }

    &--show {
      visibility: visible;
    }

    &--hide {
      visibility: hidden;
    }
  }

  .ToolIcon_type_radio,
  .ToolIcon_type_checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:not(.ToolIcon_toggle_opaque):checked + .ToolIcon__icon {
      background-color: #4c6ef5;
      color: white;
      &:active {
        background-color: #4c6ef5;
      }
      svg {
        color: white;
      }
    }

    &:focus + .ToolIcon__icon {
      box-shadow: 0 0 0 2px var(--focus-highlight-color);
    }

    &:active + .ToolIcon__icon {
      background-color: #4c6ef5;
      color: white;
    }
  }

  .ToolIcon_type_floating {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    &:active {
      background-color: transparent;
    }

    &:focus {
      box-shadow: none;
    }

    .ToolIcon__icon {
      // background-color: var(--button-gray-1);
      &:hover {
        background-color: var(--button-gray-2);
      }
      &:active {
        background-color: var(--button-gray-3);
      }

      // width: 2rem;
      // height: 2rem;
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .ToolIcon.ToolIcon__lock {
    margin-inline-end: var(--space-factor);
    &.ToolIcon_type_floating {
      margin-left: 0.1rem;
    }
  }

  .ToolIcon__keybinding {
    position: absolute;
    bottom: 2px;
    right: 3px;
    font-size: 0.5em;
    color: var(--keybinding-color);
    font-family: var(--ui-font);
    user-select: none;
  }

  // shrink shape icons on small viewports to make them fit
  @media (max-width: 425px) {
    .Shape .ToolIcon__icon {
      // width: 2rem;
      // height: 2rem;
      width: 2.5rem;
      height: 2.5rem;

      svg {
        height: 0.8em;
      }
    }
  }

  // move the lock button out of the way on small viewports
  // it begins to collide with the GitHub icon before we switch to mobile mode
  @media (max-width: 730px) {
    .App-toolbar {
      border-radius: var(--border-radius-lg);
      box-shadow: 0 0 0 1px rgb(0 0 0 / 1%), 1px 1px 5px rgb(0 0 0 / 15%) !important;
    }
    .Island {
      --padding: 0;
      background-color: var(--island-bg-color);
      box-shadow: var(--shadow-island);
      border-radius: var(--border-radius-lg);
      padding: calc(var(--padding) * var(--space-factor));
      position: relative;
      transition: box-shadow 0.5s ease-in-out;

      &.zen-mode {
        box-shadow: none;
      }
    }
    button {
      background-color: var(--button-gray-1);
    }
  }
  @media (max-width: 860px) {
    .ToolIcon.ToolIcon_type_floating {
      display: inline-block;
      position: absolute;
      right: -8px;

      margin-left: 0;
      border-radius: 20px 0 0 20px;
      z-index: 1;

      background-color: var(--button-gray-1);

      &:hover {
        background-color: var(--button-gray-1);
      }

      &:active {
        background-color: var(--button-gray-2);
      }

      .ToolIcon__icon {
        border-radius: inherit;
      }

      svg {
        position: static;
      }
    }
    .ToolIcon.ToolIcon__library {
      top: 100px;
    }

    .ToolIcon.ToolIcon__lock {
      margin-inline-end: 0;
      top: 100px;
    }

    label.ToolIcon.Shape {
      margin: auto !important;
    }

    .ToolIcon.CollabButton .ToolIcon__icon {
      margin: 0.3rem 0.5rem;
    }
    .ToolIcon.CollabButton {
      border-radius: var(--space-factor) !important;
    }
    .bgchangeBtn .popover {
      // left: 40px !important;
    }
  }

  .unlocked-icon {
    :root[dir="ltr"] & {
      left: 2px;
    }

    :root[dir="rtl"] & {
      right: 2px;
    }
  }

  .App-toolbar__extra-tools-trigger {
    box-shadow: none;
    border: 0;
    background-color: transparent;

    &:active {
      // background-color: var(--button-hover-bg);
      // box-shadow: 0 0 0 1px
      //   var(--button-active-border, var(--color-primary-darkest)) inset;
    }

    &--selected,
    &--selected:hover {
      background: var(--color-primary-light);
      color: var(--color-primary);
    }
  }

  .App-toolbar__extra-tools-dropdown {
    margin-top: 0.375rem;
    right: 0;
    min-width: 11.875rem;
    z-index: 1;
  }
}
