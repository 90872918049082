.acv {
  .acv-contextMenuContainer {
    .popover {
      position: absolute;
      z-index: 10;
      border: none;
      background: transparent;
    }
  }
}
