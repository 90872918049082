/* Container for the dropdown */
.dropdown-container {
  position: relative;
  display: inline-block;
  width: 100%;
  align-items: center;
  display: flex;
  top: 12%;
}

/* Style for the dropdown button */
.select_name {
  width: 100%;
  padding: 10px;
  text-align: left;
  background-color: white;
  color: black;
}

/* Dropdown menu with checkboxes */
.namepicker-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  display: block !important;
  height: 93px;
  overflow: auto;
}

/* Individual dropdown items */
.dropdown-item {
  margin-bottom: 8px;
}

.dropdown-item label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card_namepicker {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  text-align: center;
  height: 266.67px;
}

.card-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
}

/* Style for dropdown label */
.dropdown-label {
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
  display: block;
  top: 12%;
  position: relative;
  align-items: flex-start;
  display: flex;
}

.student_data {
  width: 164px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile_picker_student_data {
  width: 154px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name_picker {
  .d-flex {
    display: flex;
    align-items: center; /* Ensures vertical alignment */
    justify-content: space-between; /* Spreads out content */
    margin-bottom: 8px;
  }

  .name_widget {
    font-size: 20px;
    margin: 0; /* Remove bottom margin to ensure alignment */
    padding: 0;
    margin-left: 5px;
  }

  .close_button {
    font-size: 20px;
    background: none;
    border: none;
    cursor: pointer;
    width: 30px;
  }
}
