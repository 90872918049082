@import "open-color/open-color.scss";
@import "../css/variables.module.scss";

.acv {
  .App-toolbar {
    &.zen-mode {
      .ToolIcon__keybinding,
      .HintViewer {
        display: none;
      }
    }

    &__divider {
      width: 1px;
      height: 1.5rem;
      align-self: center;
      background-color: var(--default-border-color);
      margin: 0 0.25rem;

      @include isMobile {
        margin: 0;
      }
    }
  }

  .App-toolbar__extra-tools-trigger {
    box-shadow: none;
    border: 0;
    background-color: transparent;

    &:active {
      background-color: var(--button-gray-2);
    }

    &--selected,
    &--selected:hover {
      background: var(--color-primary-light);
      color: var(--color-primary);
    }
  }

  .App-toolbar__extra-tools-dropdown {
    margin-top: 0.375rem;
    right: 0;
    min-width: 11.875rem;
    z-index: 1;
  }
}
