@import "../css/variables.module";

.acv {
  .Modal__content {
    display: flex !important;
    .Island {
      display: flex;
      flex-direction: column;
    }
    .Dialog__content {
      display: flex;
      flex-direction: column;
      min-height: 0;
    }
  }
  .publish-library {
    .tags-input {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      min-height: 48px;
      width: 95%;
      border: 1px solid rgb(118, 118, 118);
      &:focus-within {
        border: 1px solid #000;
      }
      margin-left: 1rem;
      input {
        flex: 1;
        border: none;
        height: 46px;
        font-size: 14px;
        padding: 0.6rem;
        &:focus {
          outline: transparent;
          box-shadow: none;
        }
      }
    }

    #tags {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 8px 0 0 0;
    }

    .tag {
      width: auto;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      list-style: none;
      border-radius: 6px;
      margin: 0 8px 8px 8px;
      background: #494b83;
      color: #fff;

      .tag-close-icon {
        display: block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        font-size: 10px;
        margin-left: 8px;
        color: #494b83;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
        padding: 1px;
      }
    }

    @media screen and (max-width: 567px) {
      .tags-input {
        width: calc(100vw - 32px);
      }
    }
    &__fields {
      display: flex;
      flex-direction: column;

      label {
        padding: 1em;
        padding-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tag-title {
          color: #fff;
          font-size: 14px;
        }
        span {
          font-weight: 500;
          font-size: 1rem;
          color: --button-gray-3;
        }

        input,
        textarea {
          width: 70%;
          padding: 0.6em;
          // font-family: var(--ui-font);
        }

        .required {
          color: $oc-red-8;
          margin: 0.2rem;
        }
      }
    }

    &__buttons {
      display: flex;
      padding: 0.2rem 0;
      justify-content: flex-end;

      .ToolIcon__icon {
        min-width: 2.5rem;
        width: auto;
        font-size: 1rem;
      }

      .ToolIcon_type_button {
        margin-left: 0.5rem;
        padding: 0 0.5rem;
      }

      &--confirm.ToolIcon_type_button {
        background-color: #494b83;
        // background-color: $oc-blue-6;

        &:hover {
          background-color: #404174;
          box-shadow: 0 0 0 0.25rem rgba(64, 65, 116, 0.4);
        }
      }

      &--cancel.ToolIcon_type_button {
        border: 1px solid #dc3545;
        .ToolIcon__icon {
          color: #dc3545;
        }
        &:hover {
          background-color: #dc3545;
          color: #fff;
          .ToolIcon__icon {
            color: $oc-white;
          }
        }
      }

      .ToolIcon__icon {
        color: $oc-white;
        .Spinner {
          --spinner-color: #fff;
          svg {
            padding: 0.5rem;
          }
        }
      }
    }

    .selected-library-items {
      display: flex;
      padding: 0 0.8rem;
      flex-wrap: wrap;

      .single-library-item-wrapper {
        display: flex;
      }
    }

    &-note {
      padding: 1em;
      // font-style: italic;
      font-size: 14px;
      display: block;
    }
  }
  .error {
    color: red !important;
    position: absolute;
    bottom: 4px;
    left: 15px;
    transform: translateY(100%);
  }

  .input {
    width: 100%;
    border: none;
    padding: 0.4rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border: 1px solid rgb(118, 118, 118);
    width: 95%;
    border-radius: 4px;
    &:focus-within {
      border: 1px solid #000;
    }
    &:focus {
      outline: transparent;
      box-shadow: none;
    }
  }
}
