.attachment-modal {
  min-height: 300px;
  // min-height: 500px;
  .attachment-note {
    font-size: 14px;
  }
  .file-btn {
    padding: 5px 20px;
    color: #494b83;
    border: 1px solid #494b83 !important;
    border-radius: 5px;

    &:hover {
      background-color: #494b83;
      color: white;
    }
  }

  .collapse-btn {
    background-color: transparent;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    text-align: start;
    &:hover {
      background-color: transparent;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .attachment-body {
    background-color: #f1f1f1;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    height: 100%;

    .file-preview-container {
      display: flex;
      flex-direction: row;
      max-height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
      flex-wrap: wrap;
    }
    .file-preview {
      color: #2d2d2d;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      position: relative;
      padding: 5px;
      border: 1px dashed #494b83;
      width: 340px;
    }
    .preview-image {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 10px;
    }
    .file-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .file-icon-img {
      width: 40px;
      height: 40px;
    }
    .file-info {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .file-name {
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 600;
      text-align: start;
      width: 270px;
    }
    .file-type {
      font-size: 14px;
      width: 100%;
      text-align: start;
    }
    .remove-btn {
      position: absolute;
      top: -9px;
      right: -5px;
      background: #c82c2c;
      color: white;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      width: 15px;
      height: 15px;
      text-align: center;
      line-height: 20px;
      font-size: 14px;
      display: none;
      transition: opacity 0.2s;
    }
    .file-preview:hover .remove-btn {
      display: block; /* Show on hover */
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .update-btn {
    padding: 5px 20px;
    border: 1px solid #494b83 !important;
    border-radius: 5px;
    background-color: #494b83;
    color: white;
    height: fit-content;
    &:hover {
      background-color: #494b83;
      color: white;
    }
  }

  .cancel-btn {
    padding: 5px 20px;
    border: 1px solid #c82c2c !important;
    border-radius: 5px;
    color: #c82c2c;
    height: fit-content;

    &:hover {
      background-color: #c82c2c;
      color: white;
    }
  }

  .footer-note {
    font-size: 12px;
    font-weight: 600;
  }

  .progress {
    height: 6px;

    .progress-bar {
      background-color: #494b83;
    }
  }
}
