.libraryItem_dialog .popover {
  display: flex;
  flex-direction: column;
  // top: 100%;
  // width: 160px;
  border-radius: 12px;
  // box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
  //   rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
  //   rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  // -webkit-backdrop-filter: blur(12px);
  // backdrop-filter: blur(12px);
  // background: rgba(255, 255, 255, 0.8);
  margin-top: 8px;
  padding: 5px;
  // max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  .popper-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: #3a3838;
    transition: all 0.2s ease-in-out;
    padding: 10px 10px 10px 20px;
    border-radius: 12px;
    margin-bottom: 2px !important;
    &:hover {
      background: #e9ecef;
      transition: top 0.2s;
    }
  }
}

.sub-popover-wrapper {
  .popover {
    transform: translate(calc(-100% - 126px), 25%);
    z-index: 99;
  }
}

.filter-checkbox {
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}

.filter-checkbox:checked {
  background-color: #494b83; /* Change this to your desired color */
  border: none;
}

.filter-checkbox:checked::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.filter-submit-btn {
  background-color: #494b83 !important;
  color: white !important;
  border: none;
  border-radius: 5px;
  padding: 0 5px !important;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}

.filter-cancel-btn {
  background-color: #f8f9fa !important;
  color: #494b83 !important;
  border: none;
  border-radius: 5px;
  padding: 0px 8px !important;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}
