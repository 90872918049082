.flexible-group-dialog-content-wrapper {
  .acv input:focus {
    box-shadow: none !important;
    outline: none !important;
  }
  .icon-wrapper {
    width: 24px;
  }
  @media screen And (max-width: 480px) {
    .group-name-input {
      font-size: 12px;
    }
    .add-button {
      width: 100%;
    }
    .continue-button {
      width: 100%;
    }
  }
}
