.Dialog__content {
  .preview-img {
    height: 100%;
    position: relative;
  }
}

.wrapper {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
}

.fullscreen-container {
  height: 90%;
  width: 100%;
}
.fullscreen-slideshow {
  height: 100%;
}

.container {
  height: 100%;
  width: 100%;
}

.slideshow {
  height: 100%;
  width: 100%;
}

.center {
  .pagination {
    height: fit-content;
    justify-content: center;
  }

  .paginate {
    width: 100%;
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 50%);
    left: 50%;
  }
  .fullscreen-paginate {
    height: 6%;
    width: 100%;
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 50%);
    left: 50%;
  }

  .pagination:hover {
    cursor: pointer;
  }

  .dot {
    width: 10px;
    height: 10px;
    margin: 2px;
    border-radius: 50%;
    display: inline-block;
    background-color: #bbb;
    animation: none;
  }
  .active {
    max-width: 10px !important;
    background-color: #585a96;
  }
}
