.pages-wrapper {
  .group-page {
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    background-size: 100% 100%;
  }
  $color_1: #494b83;
  $color_2: #130e0e;
  $color_3: #707070;
  $background-color_1: #494b83;
  $background-color_2: #fff;
  $border-color_1: #494b83;

  .checkbox-wrapper-16 {
    height: fit-content;
    * {
      box-sizing: border-box;
      &:after {
        box-sizing: border-box;
      }
      &:before {
        box-sizing: border-box;
      }
    }
    .checkbox-wrapper {
      min-width: 100%;
    }
    .checkbox-input {
      clip: rect(0 0 0 0);
      -webkit-clip-path: inset(100%);
      clip-path: inset(100%);
      height: 1px;
      overflow: hidden;
      // position: absolute;
      white-space: nowrap;
      width: 1px;
      &:checked + .checkbox-tile {
        border-color: $border-color_1;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        color: $color_1;
        &:before {
          transform: scale(1);
          opacity: 1;
          background-color: $background-color_1;
          border-color: $border-color_1;
        }
        .checkbox-icon {
          height: 80px;
        }
        .checkbox-label {
          color: $color_1;
        }
      }
      &:focus + .checkbox-tile {
        border-color: $border-color_1;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #abade8f5;
        &:before {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
    .checkbox-tile {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // width: 7rem;
      // min-height: 7rem;
      border-radius: 0.5rem;
      border: 2px solid #b5bfd9;
      background-color: $background-color_2;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      transition: 0.15s ease;
      cursor: pointer;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        border: 2px solid #b5bfd9;
        background-color: $background-color_2;
        border-radius: 50%;
        top: 0.25rem;
        left: 0.25rem;
        opacity: 0;
        transform: scale(0);
        transition: 0.25s ease;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
      &:hover {
        border-color: $border-color_1;
        &:before {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
    .checkbox-icon {
      transition: 0.375s ease;
      height: 80px;
    }
    .checkbox-label {
      color: $color_3;
      transition: 0.375s ease;
      text-align: center;
    }
  }
  .preview-assigned-page {
    background-size: 100% 100%;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
  }
}
