.magic-output {
  padding: 15px;
}

.result-item {
  border: 1px solid #ccc;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.selected-item {
  background-color: #494b83;
  color: #fff;
}

.gpt-box-main {
  // background-color: #fff;
  // border: 1px solid #ccc;
  background-color: #aaacdd45;
  border-radius: 5px;
}

.gpt-box {
  // background-color: #fff;
  // border: 1px solid #ccc;
  // border-radius: 5px;
  height: 52vh;
  overflow-y: auto;
}

.responsePromptBox {
  padding: 20px;
}

.promptInput {
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.promptForm {
  position: relative;
}

.btn-generate-prompt {
  position: absolute;
  top: 42%;
  right: 10px;
  transform: translateY(-50%);
  background-color: #5b5e9f33;
}
.btn-attachment {
  background-color: #494b83;
  padding: 5px 15px !important;
}

.more-ideas-btn {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  svg {
    rotate: -40deg;
  }
}

.file-badge {
  right: -5px;
  top: -5px;
  background-color: #34b233 !important;
  color: white;
  border-radius: 50% !important;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  svg {
    stroke-width: 3;
  }
}

.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.dot {
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #b3d4fc;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #a7a7a7;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }

  50% {
    transform: scale(1.2);
    background-color: #494b83;
    box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
  }

  100% {
    transform: scale(0.8);
    background-color: #a7a7a7;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
}

.suggestion-boxes {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-column-gap: 14px;
  grid-row-gap: 12px;
}

.suggestion-prompt-box {
  // width: 160px;
  // height: 172px;
  background-color: #5b5e9f33;
  padding: 20px;
  border-radius: 12px;
  cursor: pointer;
}

.suggestion-prompt-box:hover {
  background-color: #5b5ea145;
}

.btn-insert {
  background-color: #494b83 !important;
  // border: 1px solid #ccc;
  color: #fff;
  border-radius: 5px;
  padding: 5px 15px !important;
  cursor: pointer;
}

.insert-buttons-div {
  flex: 1;
}

.btn-draft {
  background-color: #b3b0b0 !important;
}

.checkbox-container {
  cursor: pointer;
}

.checkbox-container input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.checkbox-container input[type="checkbox"]:checked {
  accent-color: #494b83 !important;
}

.disabled-btn {
  opacity: 0.5;
}

.promptChatForm {
  position: sticky;
  bottom: 0;
}

.promptChatInput {
  padding: 10px;
}

.user-prompt {
  background-color: #5b5e9f33 !important;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  font-size: 17px;
}

.mention-list-item:hover {
  background-color: #e6f7ff !important;
}

@media (max-width: 1300px) {
  .suggestion-boxes {
    grid-template-columns: 30% 30% 30%;
  }
}

@media (max-width: 1024px) {
  .suggestion-boxes {
    grid-template-columns: 48% 48%;
  }
}

.loader-for-external-sources {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ffffff; /* White color for contrast */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-attachment.loading {
  background-color: #ff5722; /* Different color when loading */
  color: #ffffff; /* Text color for contrast */
}
