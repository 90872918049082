.PdfSelectionDialog {
  .select-all-checkbox {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .container {
    &.border-bottom {
      border-bottom: 1px solid #ccc;
    }

    .image-container {
      object-fit: contain;
      border-radius: 5px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      cursor: pointer;
    }

    .image-container:hover + .overlay,
    .selected + .overlay {
      opacity: 1;
      cursor: pointer;
    }
    .custom-checkbox:checked + .custom-checkbox-label {
      opacity: 1;
      cursor: pointer;
    }

    .selected + .overlay {
      opacity: 1;
    }

    .overlay {
      height: 250px;
      width: 200px;
      position: absolute;
      top: 0;
      background: rgba(250, 82, 82, 0.7);
      opacity: 0;
      cursor: pointer;
    }

    .custom-checkbox {
      display: none;
    }

    .custom-checkbox-label {
      display: inline-block;
      width: 31px; /* Adjust size as needed */
      height: 25px; /* Adjust size as needed */
      background: url("../assests/check-mark.svg") no-repeat;
      background-size: cover;
      position: absolute;
      top: 50%;
      right: 40%;
      opacity: 0;
    }
  }
}
