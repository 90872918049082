.edit-mermaid-diagram-dialog {
  .Modal__content {
    max-width: 70% !important;
    background: #fff !important;
    z-index: 1;
    // min-width: 450px;
    min-height: 500px !important;
    padding: 20px;
  }

  .Dialog__content {
    padding: 0 !important;
    justify-content: normal !important;
  }

  @media screen and (max-width: 1280px) {
    .Modal__content {
      min-height: 595px !important;
      max-width: 100% !important;
      // min-width: 390px;
    }
  }
  .gpt-dialog {
    height: 100%;
    .description {
      font-size: 16px;
      // margin-bottom: 10px;
      margin-bottom: -4px;
    }

    .myTextarea {
      border: 1px solid #6a6cb7;
      border-radius: 4px;
    }
    .myTextarea:focus-visible {
      outline: 1px solid #6a6cb7;
    }

    .preview-img {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.85rem;
      box-sizing: border-box;
      flex-grow: 1;
      position: relative;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==)
        left center;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
      height: 436px;
      overflow: auto;
      border: 1px solid #ebebeb;
      border-radius: 0.5rem;
    }

    // @media screen and (max-width: 1280px) {
    //   .preview-img {
    //     height: 300px;
    //   }
    // }

    .prompt-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      flex-grow: 1;
      position: relative;
      border-radius: var(--border-radius-lg);
      border: 1px solid #6a6cb7;
      // margin-bottom: 15px;
      height: 455px;

      textarea {
        border-radius: var(--border-radius-lg);
        margin-bottom: 0;
        border: none;
        outline: none; /* Optionally, remove the outline when the textarea is focused */
      }
    }
  }

  /* Define a style for the button */
  .button {
    display: inline-block;
    padding: 10px 20px; /* Adjust padding as needed */
    font-size: 16px; /* Adjust font size as needed */
    background-color: #585a96; /* Background color */
    color: #ffffff; /* Text color */
    border: none; /* Remove border */
    border-radius: 5px; /* Add rounded corners */
    cursor: pointer; /* Change cursor to pointer on hover */
    height: fit-content;
  }

  /* Style for button hover state */
  .button:hover {
    background-color: #6a6cb7; /* Change background color on hover */
  }

  /* Style for button active state (when clicked) */
  .button:active {
    background-color: #7678c8; /* Change background color when clicked */
  }

  .ttd-dialog-output-canvas-container {
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ttd-dialog-output-canvas-container canvas {
    max-width: 100%; /* Ensure canvas doesn't overflow horizontally */
    max-height: 100%; /* Ensure canvas doesn't overflow vertically */
  }

  .ttd-dialog-output-error {
    color: red;
    font-weight: 700;
    font-size: 30px;
    word-break: break-word;
    overflow: auto;
    max-height: 100%;
    height: 100%;
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 10;

    p {
      font-weight: 500;
      font-family: Cascadia;
      text-align: left;
      white-space: pre-wrap;
      font-size: 0.875rem;
      padding: 0 10px;
    }
  }
}
