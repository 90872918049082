@import "../../css/variables.module";

.acv {
  .room-dialog-wrapper {
    // .confirm-dialog--cancel {
    //   background-color: red !important;
    // }
  }

  .workspace-dialog {
    .Modal__content {
      width: 739px !important;
    }
  }

  .RoomDialog-linkContainer {
    display: flex;
    margin: 1.5em 0;
  }

  .RoomDialog-link {
    color: var(--text-primary-color);
    min-width: 0;
    flex: 1 1 auto;
    margin-inline-end: 1em;
    display: inline-block;
    cursor: pointer;
    border: none;
    height: 2.5rem;
    line-height: 2.5rem;
    padding: 0 0.5rem;
    white-space: nowrap;
    border-radius: var(--space-factor);
    background-color: var(--button-gray-1);
  }

  .RoomDialog-emoji {
    font-family: sans-serif;
  }

  .RoomDialog-usernameContainer {
    display: flex;
    margin: 1.5em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include isMobile {
      flex-direction: column;
      align-items: stretch;
    }
  }

  @include isMobile {
    .RoomDialog-usernameLabel {
      font-weight: bold;
    }
  }

  .intro {
    font-weight: 600;
  }

  .RoomDialog-startSession {
    padding: 4px 16px;
    background-color: #585a96 !important;
    margin-left: 5px;
  }

  .ToolIcon__label {
    color: #fff !important;
  }

  .RoomDialog-username {
    background-color: var(--input-bg-color);
    border-color: var(--input-border-color);
    appearance: none;
    min-width: 0;
    flex: 1 1 auto;
    margin-inline-start: 1em;
    @include isMobile {
      margin-top: 0.5em;
      margin-inline-start: 0;
    }
    height: 2.5rem;
    font-size: 1em;
    line-height: 1.5;
    padding: 0 0.5rem;
  }

  .RoomDialog-sessionStartButtonContainer {
    display: flex;
    justify-content: right;
  }
  .RoomDialog-sessionStartButtonContainer_ {
    display: flex;
    justify-content: end;
  }
  .Modal .RoomDialog-stopSession {
    background-color: var(--button-destructive-color) !important;

    .ToolIcon__label,
    .ToolIcon__icon svg {
      color: var(--button-destructive-bg-color);
    }
  }

  .user-pill {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 20px;
    background-color: #f0f0f0; /* Adjust the background color as needed */
    margin-bottom: 5px;
  }

  .close-button {
    border: none;
    background: none;
    cursor: pointer;
    margin-left: 5px;
  }

  .showList {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    column-gap: 10px;
  }
}

.notes_div {
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #e9ecef;
  margin-bottom: 20px;
  font-size: 12px;
}

.confirmation > svg > path {
  fill: #2b8a3e !important;
}

.ToolIcon__label {
  font-family: "Karla", "Segoe UI", system-ui, "Apple Color Emoji",
    "Segoe UI Emoji", sans-serif !important;
  .Spinner {
    --spinner-color: white;
    margin-left: 7px !important;
  }
}
