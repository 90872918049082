.acv {
  .Stack {
    --gap: 5;
    display: grid;
    gap: calc(var(--space-factor) * var(--gap));
    .ToolIcon__label {
      color: #000 !important;
    }
  }

  .Stack_vertical {
    grid-template-columns: auto;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
  }

  .Stack_horizontal {
    grid-template-rows: auto;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    // gap: 5px;
  }
  .shdw {
    border-radius: 6px;
    background-color: var(--island-bg-color);
    box-shadow: var(--shadow-island);
  }
}
.w80px {
  width: 80px !important;
}
.m15vh {
  margin: 15vh;
}
.p1rem {
  padding: 1rem !important;
}
.justifycenter {
  justify-content: center !important;
}
.fixedBtm {
  position: fixed;
  bottom: 22px;
}
.fixedTopProfile {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 6px;
  background-color: var(--island-bg-color);
  box-shadow: var(--shadow-island);
  display: grid;
  gap: 5px;
}
.fixedBtmLsn {
  // position: absolute;
  bottom: 20px;
  right: 94px;
  border: 1px dashed gainsboro;
  text-align: end;
  background-color: #ffffff;
}
.students .Stack_horizontal {
  flex-direction: column;
  display: flex;
}
.students .Island {
  margin: 0 16px -7px 0;
  box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
  padding: 12px !important;
}
.students .fixedBtm {
  right: 0;
}
.r-0 {
  right: 15px;
}
@media (max-height: 768px) {
  // REMOVE COMMENT WHEN MORE FEATURES ARE AVAILABLE ON VERTICLE ACTION BAR

  .fixedBtm {
    position: absolute;
    bottom: 20px;
    right: 100px;
    .Stack.Stack_horizontal {
      grid-auto-flow: column !important;
    }

    .ToolIcon__icon {
      background-color: var(--button-gray-1) !important;
    }
  }

  .fixedBtmLsn {
    right: 255px;
  }
}
.layer-ui__wrapper__top-right.zen-mode-transition {
  // margin-top: 100px;
  height: 100%;
  .Stack.Stack_vertical {
    .Island {
      .Stack_horizontal {
        grid-auto-flow: row;
      }
    }
  }
}
