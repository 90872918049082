.avatars-group-wrapper {
  .avatars {
    display: flex;
    list-style-type: none;
    margin: auto;
    padding: 0px;
    flex-direction: row;
    &__item {
      background-color: #596376;
      border: 2px solid #ffffff;
      border-radius: 100%;
      color: #ffffff;
      display: block;
      font-family: sans-serif;
      font-size: 12px;
      font-weight: 100;
      height: 35px;
      width: 35px;
      line-height: 35px;
      text-align: center;
      transition: margin 0.1s ease-in-out;
      overflow: hidden;
      margin-left: -10px;
      &:first-child {
        z-index: 1;
      }
      &:nth-child(2) {
        z-index: 2;
      }
      &:nth-child(3) {
        z-index: 3;
      }
      &:nth-child(4) {
        z-index: 4;
      }
      &:nth-child(5) {
        z-index: 5;
      }
      &:last-child {
        z-index: 6;
      }
      img {
        width: 100%;
      }
    }
  }
  .remaining-avatars-count {
    font-size: 20px;
  }
}
