.magic-tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  position: relative;
  width: 100%;
  height: 50px;
  background: #fff;
  flex-wrap: nowrap;
  overflow: auto;
  gap: 20px;
  a {
    color: #000;
    text-decoration: none !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 50px;
    cursor: pointer;
    background-color: transparent !important;
    white-space: nowrap;

    &:hover {
      border-bottom: 2px solid #9294cd !important;
    }

    &:active {
      border-bottom: 2px solid #494b83 !important;
    }
  }
  .active-tab {
    border-bottom: 2px solid #494b83 !important;
  }
}
.magic-tab::-webkit-scrollbar {
  width: 0 !important;
  background: "transparent" !important;
  display: none !important;
}
