.about-section .comment_text {
  font-size: 14px !important;
  span {
    font-size: 12px !important;
    color: #999;
    display: flex;
    align-items: center;
  }
}

.acvLibraryPreviewDialog {
  .chat-icon {
    height: 20px;
    width: 20px;
  }
}

.use-template-btn {
  padding: 10px 20px !important; /* Adjust padding as needed */
  font-size: 18px; /* Adjust font size as needed */
  background-color: #585a96 !important; /* Background color */
  color: #ffffff !important; /* Text color */
  border: none; /* Remove border */
  border-radius: 12px !important; /* Add rounded corners */
  cursor: pointer;
  &:hover {
    background-color: #6a6cb7 !important;
  }
  margin-top: -10px !important;
}

.acv .Toast {
  left: 61% !important;
}

#tsum-tabs::-webkit-scrollbar {
  width: 0;
  height: 8px;
}
