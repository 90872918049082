@import "open-color/open-color.scss";
@import "./variables.module.scss";

.acv {
  --theme-filter: none;
  --button-destructive-bg-color: #{$oc-red-1};
  --button-destructive-color: #{$oc-red-9};
  --button-gray-1: #{$oc-gray-2};
  --button-gray-2: #{$oc-gray-4};
  --button-gray-3: #{$oc-gray-5};
  --button-special-active-bg-color: #{$oc-green-0};
  --dialog-border-color: #{$oc-gray-6};
  --dropdown-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="292.4" height="292.4" viewBox="0 0 292 292"><path d="M287 197L159 69c-4-3-8-5-13-5s-9 2-13 5L5 197c-3 4-5 8-5 13s2 9 5 13c4 4 8 5 13 5h256c5 0 9-1 13-5s5-8 5-13-1-9-5-13z"/></svg>');
  --focus-highlight-color: #{$oc-blue-2};
  --icon-fill-color: #{$oc-black};
  --icon-green-fill-color: #{$oc-green-9};
  --default-bg-color: #{$oc-white};
  --input-bg-color: #{$oc-white};
  --input-border-color: #{$oc-gray-3};
  --input-hover-bg-color: #{$oc-gray-1};
  --input-label-color: #{$oc-gray-7};
  --island-bg-color: rgba(255, 255, 255, 0.96);
  --keybinding-color: #{$oc-gray-5};
  --link-color: #{$oc-blue-7};
  --overlay-bg-color: #{transparentize($oc-white, 0.12)};
  --popup-bg-color: #{$oc-white};
  --popup-secondary-bg-color: #{$oc-gray-1};
  --popup-text-color: #{$oc-black};
  --popup-text-inverted-color: #{$oc-white};
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
  --sar: env(safe-area-inset-right);
  --sat: env(safe-area-inset-top);
  --select-highlight-color: #{$oc-blue-5};
  --shadow-island: 0 1px 5px #{transparentize($oc-black, 0.85)};
  --space-factor: 0.25rem;
  --text-primary-color: #{$oc-gray-8};
  --border-radius-md: 0.375rem;
  --border-radius-lg: 0.5rem;

  --default-button-size: 2rem;
  --default-icon-size: 1rem;
  --lg-button-size: 2.25rem;
  --lg-icon-size: 1.5rem;
  --editor-container-padding: 1rem;

  --button-hover-bg: var(--color-surface-high);
  --button-active-bg: var(--color-surface-high);
  --button-active-border: var(--color-brand-active);
  --default-border-color: var(--color-on-surface);

  --color-surface-high: #f1f0ff;
  --color-surface-mid: #f2f2f7;
  --color-surface-low: #ececf4;
  --color-surface-lowest: #ffffff;
  --color-on-surface: #1b1b1f;
  --color-brand-hover: #5753d0;
  --color-on-primary-container: #030064;
  --color-surface-primary-container: #e0dfff;
  --color-brand-active: #4440bf;
  --color-border-outline: #767680;
  --color-border-outline-variant: #c5c5d0;
  --color-surface-primary-container: #e0dfff;

  @media screen and (min-device-width: 1921px) {
    --lg-button-size: 2.5rem;
    --lg-icon-size: 1.25rem;
    --default-button-size: 2.25rem;
    --default-icon-size: 1.25rem;
  }

  &.theme--dark {
    background: $oc-black;

    &.theme--dark-background-none {
      background: none;
    }
  }

  &.theme--dark {
    --theme-filter: #{$theme-filter};
    --button-destructive-bg-color: #5a0000;
    --button-destructive-color: #{$oc-red-3};
    --button-gray-1: #363636;
    --button-gray-2: #272727;
    --button-gray-3: #222;
    --button-special-active-bg-color: #204624;
    --dialog-border-color: #{$oc-gray-9};
    --dropdown-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="292.4" height="292.4" viewBox="0 0 292 292"><path fill="%23ced4da" d="M287 197L159 69c-4-3-8-5-13-5s-9 2-13 5L5 197c-3 4-5 8-5 13s2 9 5 13c4 4 8 5 13 5h256c5 0 9-1 13-5s5-8 5-13-1-9-5-13z"/></svg>');
    --focus-highlight-color: #{$oc-blue-6};
    --icon-fill-color: #{$oc-gray-4};
    --icon-green-fill-color: #{$oc-green-4};
    --default-bg-color: #121212;
    --input-bg-color: #121212;
    --input-border-color: #2e2e2e;
    --input-hover-bg-color: #181818;
    --input-label-color: #{$oc-gray-2};
    --island-bg-color: rgba(30, 30, 30, 0.98);
    --keybinding-color: #{$oc-gray-6};
    --overlay-bg-color: #{transparentize($oc-gray-8, 0.88)};
    --popup-bg-color: #2c2c2c;
    --popup-secondary-bg-color: #222;
    --popup-text-color: #{$oc-gray-4};
    --popup-text-inverted-color: #2c2c2c;
    --select-highlight-color: #{$oc-blue-4};
    --shadow-island: 0 1px 5px #{transparentize($oc-black, 0.5)};
    --text-primary-color: #{$oc-gray-4};
    --border-radius-md: 0.375rem;
    --border-radius-lg: 0.5rem;
  }
}
