.gpt-dialog-container {
  .Modal__content {
    width: fit-content;
    max-width: 95% !important;
    background: #fff !important;
    z-index: 1;
    // min-width: 450px;
    min-height: 800px !important;
    padding: 20px;
  }

  @media screen and (max-width: 1280px) {
    .Modal__content {
      min-height: 595px !important;
      max-width: 100% !important;
      // min-width: 390px;
    }
  }

  .Dialog__title {
    margin-bottom: 0;
    text-align: left !important;
    padding-top: 0;
  }
  .Dialog__content {
    overflow: none;
    padding: 0 16px !important;
    justify-content: normal !important;
  }

  .gpt-dialog {
    .description {
      font-size: 16px;
      // margin-bottom: 10px;
      margin-bottom: -4px;
    }

    .myTextarea {
      border: 1px solid #ced4da; /* Set the border color here */
      margin-bottom: 10px;
      border-radius: 4px;
    }

    /* Define a style for the button */
    .button {
      display: inline-block;
      padding: 10px 20px; /* Adjust padding as needed */
      font-size: 16px; /* Adjust font size as needed */
      background-color: #585a96; /* Background color */
      color: #ffffff; /* Text color */
      border: none; /* Remove border */
      border-radius: 5px; /* Add rounded corners */
      cursor: pointer; /* Change cursor to pointer on hover */
      height: fit-content;
    }

    /* Style for button hover state */
    .button:hover {
      background-color: #6a6cb7; /* Change background color on hover */
    }

    /* Style for button active state (when clicked) */
    .button:active {
      background-color: #7678c8; /* Change background color when clicked */
    }
    .tos-text {
      color: #7678c8;
      // border-bottom: 1px solid #7678c8;
    }

    .disabled,
    .disabled:hover {
      background-color: #e9ecef;
      color: #ced4da;
      cursor: not-allowed;
    }

    .preview-img {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.85rem;
      box-sizing: border-box;
      flex-grow: 1;
      position: relative;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==)
        left center;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
      height: 500px;
      margin-bottom: 15px;
      overflow: auto;
      border: 1px solid #ebebeb;
      border-radius: 0.5rem;
    }

    @media screen and (max-width: 1280px) {
      .preview-img {
        height: 300px;
      }
    }

    .prompt-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      flex-grow: 1;
      position: relative;
      border-radius: var(--border-radius-lg);
      border: 1px solid #6a6cb7;
      // margin-bottom: 15px;
      height: 455px;

      textarea {
        border-radius: var(--border-radius-lg);
        margin-bottom: 0;
        border: none;
        outline: none; /* Optionally, remove the outline when the textarea is focused */
      }
    }

    // @media screen and (max-width: 1280px) {
    //   .prompt-preview {
    //     height: 300px;
    //   }
    // }
  }
}

.gpt-icon {
  right: -8px;
  top: 250px;
  .tool-button {
    padding: 0;
    border-radius: 20px 0 0 20px !important;
  }
  .ToolIcon_type_button {
    border-radius: 20px 0 0 20px !important;
  }

  .popover-mobile {
    position: absolute;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    z-index: 1;
    left: -5px;
    top: 50%;
    transform: translate(-100%, -50%);
  }
}

.credit-limit-wrapper {
  border-radius: 20px;
  .credit-icon-wrapper {
    width: 25px;
  }
  height: fit-content;
}

@media (max-width: 1280px) {
  .layer-ui__wrapper {
    .FixedSideContainer.FixedSideContainer_side_top {
      .gpt-dialog {
        border: 1px solid #0000006e;
        position: absolute;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
        z-index: 1;
        left: 0;
        top: -200%;
        transform: translate(-100%, -50%);
        min-width: 450px;
        min-height: 250px;
        padding: 20px;

        .title {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .description {
          font-size: 16px;
          margin-bottom: 10px;
        }

        .myTextarea {
          border: 1px solid #ced4da; /* Set the border color here */
          margin-bottom: 10px;
          border-radius: 4px;
        }

        /* Define a style for the button */
        .button {
          display: inline-block;
          padding: 10px 20px; /* Adjust padding as needed */
          font-size: 16px; /* Adjust font size as needed */
          background-color: #585a96; /* Background color */
          color: #ffffff; /* Text color */
          border: none; /* Remove border */
          border-radius: 5px; /* Add rounded corners */
          cursor: pointer; /* Change cursor to pointer on hover */
        }

        /* Style for button hover state */
        .button:hover {
          background-color: #6a6cb7; /* Change background color on hover */
        }

        /* Style for button active state (when clicked) */
        .button:active {
          background-color: #7678c8; /* Change background color when clicked */
        }
        .tos-text {
          color: #7678c8;
          // border-bottom: 1px solid #7678c8;
        }
      }
    }
  }
}

@media (max-width: 736px) {
  .layer-ui__wrapper {
    .FixedSideContainer.FixedSideContainer_side_top {
      .gpt-dialog {
        border: 1px solid #0000006e;
        position: absolute;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
        z-index: 1;
        left: 0;
        top: -200%;
        transform: translate(-100%, -50%);
        min-width: 450px;
        min-height: 250px;
        padding: 20px;

        .title {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .description {
          font-size: 16px;
          margin-bottom: 10px;
        }

        .myTextarea {
          border: 1px solid #ced4da; /* Set the border color here */
          margin-bottom: 10px;
          border-radius: 4px;
        }

        /* Define a style for the button */
        .button {
          display: inline-block;
          padding: 10px 20px; /* Adjust padding as needed */
          font-size: 16px; /* Adjust font size as needed */
          background-color: #585a96; /* Background color */
          color: #ffffff; /* Text color */
          border: none; /* Remove border */
          border-radius: 5px; /* Add rounded corners */
          cursor: pointer; /* Change cursor to pointer on hover */
        }

        /* Style for button hover state */
        .button:hover {
          background-color: #6a6cb7; /* Change background color on hover */
        }

        /* Style for button active state (when clicked) */
        .button:active {
          background-color: #7678c8; /* Change background color when clicked */
        }
        .tos-text {
          color: #7678c8;
          // border-bottom: 1px solid #7678c8;
        }
      }
    }
  }
}

@media (max-width: 1413px) {
  .layer-ui__wrapper {
    .FixedSideContainer.FixedSideContainer_side_top {
      .gpt-dialog {
        border: 1px solid #0000006e;
        position: absolute;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
        z-index: 1;
        left: 0;
        top: -200%;
        transform: translate(-100%, -50%);
        min-width: 450px;
        min-height: 250px;
        padding: 20px;

        .title {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .description {
          font-size: 16px;
          margin-bottom: 10px;
        }

        .myTextarea {
          border: 1px solid #ced4da; /* Set the border color here */
          margin-bottom: 10px;
          border-radius: 4px;
        }

        /* Define a style for the button */
        .button {
          display: inline-block;
          padding: 10px 20px; /* Adjust padding as needed */
          font-size: 16px; /* Adjust font size as needed */
          background-color: #585a96; /* Background color */
          color: #ffffff; /* Text color */
          border: none; /* Remove border */
          border-radius: 5px; /* Add rounded corners */
          cursor: pointer; /* Change cursor to pointer on hover */
        }

        /* Style for button hover state */
        .button:hover {
          background-color: #6a6cb7; /* Change background color on hover */
        }

        /* Style for button active state (when clicked) */
        .button:active {
          background-color: #7678c8; /* Change background color when clicked */
        }
        .tos-text {
          color: #7678c8;
          // border-bottom: 1px solid #7678c8;
        }
      }
    }
  }
}

textarea.dark::placeholder {
  color: #fff;
}
.dark {
  background-color: grey;
  color: white;
}

.ttd-dialog-output-canvas-container {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ttd-dialog-output-error {
  color: red;
  font-weight: 700;
  font-size: 30px;
  word-break: break-word;
  overflow: auto;
  max-height: 100%;
  height: 100%;
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: 10;

  p {
    font-weight: 500;
    font-family: Cascadia;
    text-align: left;
    white-space: pre-wrap;
    font-size: 0.875rem;
    padding: 0 10px;
  }
}

.ttd-dialog-output-canvas-container canvas {
  max-width: 100%; /* Ensure canvas doesn't overflow horizontally */
  max-height: 100%; /* Ensure canvas doesn't overflow vertically */
}

.active_template {
  background: #585a96 !important;
  color: white !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.deactive_template {
  padding-left: 10px !important;
  padding-right: 10px !important;
  border: 1px solid #ced4da !important;
}

.template_ {
  margin-right: 10px !important;
  margin-bottom: 0px !important;
  color: #585a96 !important;
  font-weight: 600 !important;
}

.flowchart_type {
  margin-bottom: 6px !important;
  font-size: 14px !important;
  font-style: italic !important;
  color: #808080c7 !important;
}

.credit_div {
  bottom: 0 !important;
  // position: absolute !important;
  display: grid !important;
  // gap: 25px !important;
}

.form-select:disabled {
  background-color: white;
  background-image: none;
}

.mermaid-link {
  font-weight: 500;
  text-decoration: none;
  color: var(--link-color);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mermaid-link:hover {
  text-decoration: underline;
}

.gpt-dialog-container {
  .popover {
    transform: translate(50%, -100%);
    top: 7%;
    transition: top 0.5s ease-in-out;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    width: 100%;
    max-width: 100%;

    .title {
      margin-bottom: 6px;
      color: black;
      font-weight: 600;
    }
  }
  #regenerate_chart {
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 12px;
    color: black;
    padding: 12px;
    border: none;
  }
}

.close_popover {
  width: 14px;
  height: 25px;
  color: black;
  cursor: pointer;
}

.regenerate_button {
  border-radius: 15px;
  padding: 5px 10px;
  width: fit-content;
  padding: 5px 20px !important;

  .regenerate_loader > .Spinner {
    --spinner-color: white;
  }
}

.preview_with_view_as_mermaid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.undo-gpt {
  width: 2.3rem;
  height: 2.3rem;
  color: var(--icon-fill-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--space-factor);
}
.undo-gpt > svg {
  height: 1.2em;
}
