@import url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff2");
@import url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-bold.woff2");
@import url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff2");
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@300;400;600;800&display=swap");

.acv {
  * {
    font-family: "Karla", "Segoe UI", system-ui, "Apple Color Emoji",
      "Segoe UI Emoji", sans-serif;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
  .layer-ui__wrapper__footer-center {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-inline-start: auto;
  }

  .encrypted-icon {
    border-radius: var(--space-factor);
    color: var(--icon-green-fill-color);
    margin-top: auto;
    margin-bottom: auto;
    margin-inline-start: auto;
    margin-inline-end: 0.6em;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  .bg-brand {
    background-color: #494b83 !important;
  }

  .backg-brand {
    background-color: #dcdcdc !important;
  }

  @keyframes pulse {
    0% {
      transform: scale(1.5);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1.5);
    }
  }

  .element {
    animation: pulse 2s infinite;
  }

  .pr-2 {
    padding-right: 1rem;
  }
  .pageBtn {
    width: 115px;
    height: 36px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin-left: -3px;
    position: fixed;
    top: 10px;
    border-radius: 3px !important;
    margin-inline-start: 1.1em;
  }

  @media screen and (max-width: 1024px) {
    .pageBtn {
      margin-inline-start: 0.3em;
      width: fit-content;
    }
  }
  @media screen and (max-width: 884px) {
    .pageBtn {
      margin-inline-start: 0.2em;
    }
  }
  .panelColumn {
    .bgchangeBtn {
      justify-content: left;
    }
    .darkmodeBtn {
      justify-content: left;
    }
  }
  .bgchangeBtn {
    display: flex;
    justify-content: right;
    margin-bottom: 0.2rem;
    margin-top: 0.6rem;
  }
  .darkmodeBtn {
    display: flex;
    // justify-content: right;
  }
  ::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.5);
    border-color: transparent;
    background-clip: content-box;
    border-width: 0.25rem;
    border-style: solid;
    border-radius: 0.5625rem;
    min-height: 60px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
}
.user-profile {
  border-radius: 1rem;
  background: rgb(232, 232, 232) !important;
  width: 2rem;
  height: 2rem;
  margin: 0 1rem;
  text-align: center;
  left: 2rem;
  line-height: 2rem;
}

// .user-profile > .ui-image {
//   border-radius: 1rem;
//   left: auto !important;
//   position: unset !important;
//   width: 2rem;
//   height: 2rem;
// }

.form {
  position: relative;
}

.form .fa-search {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #9ca3af;
}

.form span {
  position: absolute;
  right: 17px;
  top: 13px;
  padding: 2px;
  border-left: 1px solid #d1d5db;
}

.left-pan {
  padding-left: 7px;
}

.left-pan i {
  padding-left: 10px;
}

.form-input {
  height: 45px;
  text-indent: 33px;
  border-radius: 10px;
}

.form-input:focus {
  box-shadow: none;
}
