.image-picker {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background: #fff;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;

  .upload-area {
    border: 2px dashed #b3b3b3;
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
    cursor: pointer;
    color: #b3b3b3;
    font-size: 1rem;
    transition: all 0.3s ease;
    height: 171.67px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &.drag-over {
      border-color: #b3b3b3;
      background-color: rgb(96 96 96 / 10%);
    }
  }
  .upload_area {
    border: 2px dashed #b3b3b3;
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
    cursor: pointer;
    color: #b3b3b3;
    font-size: 1rem;
    transition: all 0.3s ease;
    height: 83px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &.drag-over {
      border-color: #b3b3b3;
      background-color: rgb(96 96 96 / 10%);
    }
  }

  .hidden {
    display: none;
  }

  .image-preview-wrapper {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    height: 80px;

    .image-preview {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .remove-button {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 16px;
      height: 16px;
      border: none;
      background: #ff4d4d;
      color: white;
      border-radius: 50%;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background: #d93636;
      }
    }
  }

  .image_row_container {
    height: 88px;
    overflow: auto;
  }
}
