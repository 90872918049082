@import "../css/variables.module";

// this is loosely based on the longest hint text
$wide-viewport-width: 1000px;

.acv {
  .HintViewer {
    // pointer-events: none;
    box-sizing: border-box;
    position: absolute;
    display: flex;
    justify-content: center;
    // left: 0;
    left: -7%;
    top: 100%;
    // max-width: 100%;
    // width: 100%;
    width: 115%;
    margin-top: 6px;
    text-align: center;
    color: $oc-gray-6;
    font-size: 0.8rem;

    @include isMobile {
      position: static;
      padding-right: 2em;
    }

    > span {
      padding: 0.2rem 0.4rem;
      background-color: var(--overlay-bg-color);
      border-radius: 4px;
    }

    .library-actions--cancel > div > svg {
      color: white;
    }

    .library-actions--cancel {
      font-family: "Karla", "Segoe UI", system-ui, "Apple Color Emoji",
        "Segoe UI Emoji", sans-serif;
      font-size: 15px;
      height: 34px;
      background-color: rgb(224, 49, 49);
    }

    .save-item > div > svg {
      color: white;
    }

    .save-item {
      font-family: "Karla", "Segoe UI", system-ui, "Apple Color Emoji",
        "Segoe UI Emoji", sans-serif;
      font-size: 15px;
    }

    .reset-item {
      font-family: "Karla", "Segoe UI", system-ui, "Apple Color Emoji",
        "Segoe UI Emoji", sans-serif;
      font-size: 15px;
    }
  }
}
