.ProseMirror:focus {
  outline: none;
}

code {
  background-color: #f4f4f4;
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  border-radius: 3px;
}

#id_text {
  .tiptap {
    &:focus-visible {
      outline: none;
    }
    &:focus {
      outline: none;
    }
    p {
      margin-bottom: 0;
    }
  }
}
