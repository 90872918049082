.select {
  position: relative;

  .select-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    background-color: #e9ecef;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    cursor: pointer;
  }

  .option-container {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease;
    display: flex;
    border-left: 1px solid #e9ecef;
    border-right: 1px solid #e9ecef;
    border-bottom: 1px solid #e9ecef;
    background-color: #fff;
    position: absolute;
    width: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    &.open {
      max-height: 200px; // Adjust the max-height as needed
      padding: 10px 0;
    }
  }
  .title-option {
    padding: 3px 10px;
    cursor: pointer;
  }

  .color-popover {
    position: absolute;
    z-index: 2;
    right: 0px;
    // top: 40px;
  }
  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .input-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  input[type="text"] {
    padding-right: 20px; /* Add space for the close icon */

    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
    border-radius: 1px solid #e9ecef;
  }
  :focus-visible,
  :focus {
    outline: unset;
  }

  .close-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    height: 15px;
    width: 15px;
    cursor: pointer;
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-size: 16px 12px;
    background-position: right 0.80rem center;
  }
}
