@import "../css/variables.module.scss";

.alert-dialog {
  .Modal .alert-okay {
    background-color: red !important;

    // .ToolIcon__label,
    // .ToolIcon__icon svg {
    //   color: var(--button-destructive-bg-color);
    // }
  }
}
