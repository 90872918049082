.timer-section {
  .wrapper {
    width: fit-content;
  }
  .timer {
    font-size: 30px;
    font-weight: bold;
    color: #adb5bd;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid #ced4da;
    border-radius: 10px;
    width: 150px;
    padding: 0px 20px;
  }

  .plus-btn,
  .minus-btn,
  .restart-btn,
  .pause-btn,
  .play-btn,
  .start-btn,
  .spin-again-btn {
    background-color: #494b83 !important;
    color: #fff !important;
    // margin: 0 5px !important;
    border-radius: 5px;
  }

  .plus-btn .disabled,
  .minus-btn .disabled,
  .restart-btn .disabled,
  .pause-btn .disabled,
  .play-btn .disabled,
  .start-btn .disabled {
    opacity: 0.2;
  }

  .spin-again-btn {
    width: fit-content;
    padding: 0 30px !important;

    .ToolIcon__icon {
      color: #fff !important;
    }
  }

  .checkbox-wrapper-46 input[type="checkbox"] {
    display: none;
    visibility: hidden;
  }

  .checkbox-wrapper-46 .cbx {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
  }
  .checkbox-wrapper-46 .cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
  }
  .checkbox-wrapper-46 .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #9098a9;
    transition: all 0.2s ease;
  }
  .checkbox-wrapper-46 .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #ffffff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }
  .checkbox-wrapper-46 .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #494b83;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
  }
  .checkbox-wrapper-46 .cbx span:last-child {
    padding-left: 8px;
  }
  .checkbox-wrapper-46 .cbx:hover span:first-child {
    border-color: #494b83;
  }

  .checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child {
    background: #494b83;
    border-color: #494b83;
    animation: wave-46 0.4s ease;
  }
  .checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
  }
  .checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
  }

  @keyframes wave-46 {
    50% {
      transform: scale(0.9);
    }
  }

  .checkbox-container {
    cursor: pointer;
  }

  .checkbox-container input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .checkbox-container input[type="checkbox"]:checked {
    accent-color: #494b83 !important;
  }
  .dropdown-menu-minutes {
    min-width: 20px;
    max-height: 300px;
    overflow: scroll;
  }

  .dropdown-menu-minutes::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
  }
}

.acv .btn-floating-timer {
  margin: 0 3px !important;
}

.acv .btn-floating-timer .ToolIcon__icon {
  width: 1rem;
  height: 1rem;
}
