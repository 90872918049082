.acv {
  .Island {
    --padding: 0;
    // background-color: var(--island-bg-color);
    // box-shadow: var(--shadow-island);
    border-radius: 4px;
    padding: calc(var(--padding) * var(--space-factor));
    position: relative;
    transition: box-shadow 0.5s ease-in-out;

    &.zen-mode {
      box-shadow: none;
    }
  }
}

.layer-ui__wrapper__top-right.zen-mode-transition {
  background-color: var(--island-bg-color);
  box-shadow: var(--shadow-island);
}
