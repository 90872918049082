.multiselect {
  width: 200px;

  .selectBox {
    position: relative;
  }

  .selectBox select {
    width: 100%;
    padding: 4px 1px;
    border-radius: 5px;
  }

  .overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .custom-select {
    border: 1.5px solid var(--button-gray-1) !important;
  }

  #checkboxes {
    display: none;
    border: 1px #dadada solid;
    position: absolute;
    z-index: 5;
    background-color: white;
    width: 200px;
  }
}
