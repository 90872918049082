@import "open-color/open-color";

:root {
  --navbar-width: 256px;
  --navbar-width-min: 80px;
  --navbar-dark-primary: #fff;
  --navbar-dark-secondary: #2c3e50;
  --navbar-light-primary: #e9ecef;
  --navbar-light-secondary: #3a3838;
  // --navbar-light-secondary: #8392a5;
}

.acv {
  .libraryDialog {
    .Modal__content {
      // max-width: 72% !important;
      // height: 85% !important;
      font-family: "Karla" !important;
      overflow: hidden;

      .Island {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    .Dialog__title {
      padding: 0;
      margin-bottom: 0;
    }
    .Dialog__content {
      min-height: 0;
      display: flex;
      flex-direction: column;

      .Header {
        min-height: 0;

        .row {
          height: 100%;
        }
      }
    }

    @media (max-width: 1400px) {
      .Modal__content {
        // max-width: 100% !important;
        // height: 100% !important;
        font-family: "Karla" !important;
      }
      .closeIcon {
        right: 15px;
        top: 17px;
      }
    }

    // .Modal__close {
    //   position: absolute;
    //   right: 10px;
    //   top: 15px;
    //   padding: 8px;
    //   background: #585a96;
    //   border-radius: 50px;
    //   color: #fff !important;
    // }

    .offcanvas-end > .title,
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      font-family: "Karla" !important;
      line-height: 24px;
      padding: 0 !important;
      margin-bottom: 25px;
    }

    .back {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .subTitle {
      color: #585a96 !important;
      font-weight: 700;
      font-size: 16px !important;
      line-height: 24px;
    }
    .content {
      padding: 4px 16px;
      margin-top: 5px;
    }
    .ToolIcon {
      font-family: "Karla" !important;
    }
    .subTitle:hover {
      background-color: transparent !important;
      border-radius: 4px !important;
    }
    .active_div:hover,
    .content:hover,
    .active {
      width: 100%;
      background-color: #585a96 !important;
      border-radius: 4px !important;
      // border-top-right-radius: 8px !important; /* Radius for the top-right corner */
      // border-bottom-right-radius: 8px !important;
      color: #fff !important;
      max-width: 190px;
      cursor: pointer;
      svg {
        path {
          fill: #fafafa;
        }
      }
    }

    .active_div {
      margin-top: 3px;
      margin-bottom: 3px;
    }

    .active_div:hover {
      .content {
        color: var(--popup-text-inverted-color);
        .ToolIcon__icon {
          svg {
            path {
              fill: var(--popup-text-inverted-color);
            }
          }
        }
      }
    }

    .active_div_sidebar:hover,
    .content:hover,
    .active_sidebar {
      // width: 100%;
      background-color: #585a96 !important;
      border-radius: 4px !important;
      // border-top-right-radius: 8px !important; /* Radius for the top-right corner */
      // border-bottom-right-radius: 8px !important;
      color: #fff !important;
      max-width: 100%;
      cursor: pointer;
      svg {
        path {
          fill: #fafafa;
        }
      }
    }

    .active_sidebar {
      // width: 100%;
      background-color: #585a96 !important;
      border-radius: 4px !important;
      // border-top-right-radius: 8px !important; /* Radius for the top-right corner */
      // border-bottom-right-radius: 8px !important;
      color: #fff !important;
      max-width: 100%;
      margin-left: 5px;
      margin-right: 5px;
      cursor: pointer;
      svg {
        path {
          fill: #fafafa;
        }
      }
    }

    .active_div_sidebar {
      margin-top: 3px;
      margin-bottom: 3px;
      margin-left: 5px;
      margin-right: 5px;
      max-width: 100%;
    }

    .active_div_sidebar:hover {
      .content {
        color: var(--popup-text-inverted-color);
        .ToolIcon__icon {
          svg {
            path {
              fill: var(--popup-text-inverted-color);
            }
          }
        }
      }
    }

    .sidebar-button-wrapper .ToolIcon_type_button {
      color: var(--text-primary-color);
      width: 100%;
      justify-content: flex-start;
    }

    // .ToolIcon_type_button {
    //   color: var(--text-primary-color);
    // }

    .content:focus,
    button:focus,
    .subTitle:focus,
    .ToolIcon_type_button:focus {
      border: none !important;
      box-shadow: none !important;
      color: var(--text-primary-color);
    }
    // .ToolIcon:hover,
    button:hover {
      background-color: transparent;
    }
    .likeSection {
      margin-top: -5px;
      margin-right: 9px;
    }
    .likeFont {
      padding-bottom: 5px;
      color: #f05454;
      font-family: "Karla";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }
    .card {
      box-sizing: border-box;
      // width: 335px;
      // background: #ffffff;
      // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      background-color: var(--island-bg-color);
      box-shadow: var(--shadow-island);
      flex: none;
      order: 0;
      flex-grow: 0;
      padding: 16px;
    }

    .card-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }

    .collectionImage {
      // background-color: #d9d9d9;
      min-height: 120px;
      max-width: 100%;
      max-height: 120px;
    }
    .author-image {
      object-fit: cover;
      margin-right: 5px;
    }
    .date {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
    }
    .infoBtn {
      padding: 4px 16px;
      border: 1px solid #585a96 !important;
      margin-left: 5px;
      color: #585a96;
    }
    .infoBtn:hover {
      background-color: #585a96 !important;
      color: #ffffff !important;
    }

    input[type="text"],
    select {
      // width: 40%;
      padding: 5px 15px;
      margin: 8px 0;
      display: inline-block;
      border: 1px solid var(--text-primary-color);
      border-radius: 4px;
      box-sizing: border-box;
      background-color: var(--island-bg-color);
    }

    .closeIcon {
      right: 15px;
      .ToolIcon__icon {
        svg {
          path {
            fill: #bfbfbf;
          }
          height: 1rem;
        }
      }
    }

    @media (max-width: 730px) {
      // button {
      //   background-color: transparent !important;
      // }
      .more-info-btn {
        background-color: #585a96 !important;
      }
    }

    .offcanvas-end {
      width: 392px;
      // transform: none;
      max-height: 100%;
      background-color: var(--popup-bg-color);
    }
    .modal-backdrop {
      z-index: 0;
      background-color: var(--popup-bg-color);
      width: 0;
    }

    .multiSelect .dropdown__select {
      background-color: var(--popup-bg-color);
    }

    .footer {
      // position: absolute;
      bottom: 0;
      padding: 15px 0;
      margin-left: 15px;
    }

    .offcanvas-body {
      padding: 0;
    }

    .clear_filter {
      background: #30475e;
      border-radius: 20px;
      align-items: center;
      padding: 3px 8px 0 8px;
      color: #ffffff;
      font-weight: 500;
      font-size: 13px;
      cursor: pointer;
    }

    .filter_val {
      background: #f0f0f0 !important;
      border-radius: 20px;
      padding: 0px 8px;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: #121212;
      .ToolIcon__icon {
        svg {
          path {
            fill: #8c8c8c !important;
          }
        }
        // padding-top: 5px;
        height: 1.5rem;
        width: 1.5rem;
      }
    }

    #tsum-tabs input {
      display: none;
    }

    #tsum-tabs label {
      display: inline-block;
      margin: 0 0 -1px;
      padding: 15px 25px 35px 0;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      color: var(--icon-fill-color);

      cursor: pointer;
    }

    #tsum-tabs section {
      display: none;
    }

    #tsum-tabs input:checked + label {
      color: #585a96;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
    }

    #tsum-tabs #tab1:checked ~ #content1,
    #tsum-tabs #tab2:checked ~ #content2,
    #tsum-tabs #tab3:checked ~ #content3 {
      display: flex;
    }
  }
  .offcanvas-header {
    padding: 0 1rem;
    .btn-close {
      color: var(--icon-fill-color) !important;
    }
  }

  .backIcon:active {
    background-color: transparent !important;
  }
  .right-container {
    width: calc(100% - 200px);
  }
}

.libraryItemDialog {
  .Modal__content {
    background-color: var(--navbar-dark-primary) !important;
    max-width: 72% !important;
    height: 85% !important;
  }
  .Dialog__content {
    height: 100% !important;
    padding: 0 !important;
  }

  @media screen and (max-width: 728px) {
    .Modal__content {
      max-width: 100% !important;
      height: 100% !important;
      // min-width: 390px;
    }
  }
}

#nav-toggle:checked ~ #nav-header {
  width: calc(var(--navbar-width-min) - 50px);
}
#nav-toggle:checked ~ #nav-content,
#nav-toggle:checked ~ #nav-footer {
  width: var(--navbar-width-min);
}
#nav-toggle:checked ~ #nav-header #nav-title {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s;
}
#nav-toggle:checked ~ #nav-header label[for="nav-toggle"] {
  left: calc(50% - 8px);
  transform: translate(-50%);
}

#nav-toggle:checked ~ #nav-header #nav-toggle-burger {
  background: var(--navbar-light-secondary);
}
#nav-toggle:checked ~ #nav-header #nav-toggle-burger:before,
#nav-toggle:checked ~ #nav-header #nav-toggle-burger::after {
  width: 16px;
  background: var(--navbar-light-secondary);
  transform: translate(0, 0) rotate(0deg);
}
#nav-toggle:checked ~ #nav-content .nav-button span,
#nav-toggle:checked ~ #nav-footer .nav-button span {
  opacity: 0;
  transition: opacity 0.1s;
}
#nav-toggle:checked ~ #nav-content .nav-button .fas,
#nav-toggle:checked ~ #nav-footer .nav-button .fas {
  min-width: 100%;
  fill: var(--navbar-light-secondary);
}

#nav-toggle:checked ~ #nav-footer .nav-button {
  justify-content: unset;
}

.nav-button i:hover svg path,
.nav-button i svg path {
  fill: var(--navbar-light-secondary);
}

#nav-toggle:checked ~ #nav-footer #nav-footer-avatar {
  margin-left: 0;
  left: 50%;
  transform: translate(-50%);
}
#nav-toggle:checked ~ #nav-footer #nav-footer-titlebox,
#nav-toggle:checked ~ #nav-footer label[for="nav-footer-toggle"] {
  opacity: 0;
  transition: opacity 0.1s;
  pointer-events: none;
}

#nav-bar {
  // position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: var(--navbar-dark-primary);
  display: flex;
  flex-direction: column;
  color: var(--navbar-dark-primary);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  border-right: solid 1px #e0e0e0;
}

#nav-bar hr {
  margin: 0;
  position: relative;
  left: 16px;
  right: 16px;
  width: calc(100% - 32px);
  border: none;
  border-top: solid 1px var(--navbar-dark-secondary);
}

#nav-bar a {
  color: inherit;
  text-decoration: inherit;
}
#nav-bar input[type="checkbox"] {
  display: none;
}

#nav-header {
  position: relative;
  width: var(--navbar-width);
  left: 31px;
  width: calc(var(--navbar-width) - 32px);
  min-height: 80px;
  background: var(--navbar-dark-primary);
  border-radius: 16px;
  z-index: 1;
  display: flex;
  align-items: center;
  transition: width 0.2s;
}

#nav-header hr {
  position: absolute;
  bottom: 0;
}

#nav-title {
  font-size: 1.5rem;
  transition: opacity 1s;
  font-weight: 600;
  color: #000 !important;
}

label[for="nav-toggle"] {
  position: absolute;
  right: 0;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#nav-toggle-burger {
  position: relative;
  width: 16px;
  height: 2px;
  background: var(--navbar-dark-primary);
  border-radius: 99px;
  transition: background 0.2s;
}

#nav-toggle-burger:before,
#nav-toggle-burger:after {
  content: "";
  position: absolute;
  top: -6px;
  width: 10px;
  height: 2px;
  background: var(--navbar-light-secondary);
  border-radius: 99px;
  transform: translate(2px, 8px) rotate(30deg);
  transition: 0.2s;
}
#nav-toggle-burger:after {
  top: 6px;
  transform: translate(2px, -8px) rotate(-30deg);
}

#nav-content {
  margin: -16px 0;
  padding: 16px 0;
  position: relative;
  flex: 1;
  width: var(--navbar-width);
  background: var(--navbar-dark-primary);
  box-shadow: 0 0 0 16px var(--navbar-dark-primary);
  direction: rtl;
  overflow-x: hidden;
  transition: width 0.2s;
}
#nav-content::-webkit-scrollbar {
  width: 0;
  height: 8px;
}
// #nav-content::-webkit-scrollbar-thumb {
//   border-radius: 99px;
//   background-color: #d62929;
// }

// #nav-content::-webkit-scrollbar-button {
//   height: 16px;
// }

.nav-button {
  position: relative;
  margin: 8px 16px 8px 9px; /* Add margin to create space between buttons */
  height: 52px;
  display: flex;
  align-items: center;
  color: var(--navbar-light-secondary);
  direction: ltr;
  cursor: pointer;
  z-index: 1;
  transition: color 0.2s, margin 0.2s;
  border-radius: 16px; /* Add border-radius directly to buttons */
}

.nav-button span {
  transition: opacity 1s;
}

.nav-button div > svg {
  min-width: 3rem !important;
  text-align: center !important;
  opacity: 0.7;
}

.nav-button:hover,
.nav-button.activeItem {
  background: var(--navbar-light-primary);
  transition: top 0.2s;
}

/* Highlight styles */
#nav-content-highlight {
  position: absolute;
  left: 16px;
  right: 16px;
  height: 52px;
  background: var(--navbar-light-primary);
  border-radius: 16px;
  transition: top 0.2s, height 0.2s, width 0.2s;
  z-index: 0;
}

/* Add a data attribute to manage the position */
.nav-button[data-index="0"]:hover ~ #nav-content-highlight,
.nav-button[data-index="0"].activeItem ~ #nav-content-highlight {
  top: calc(8px + 0 * 62px); /* Adjust calculation as necessary */
}

.nav-button[data-index="1"]:hover ~ #nav-content-highlight,
.nav-button[data-index="1"].activeItem ~ #nav-content-highlight {
  top: calc(8px + 1 * 62px);
}

.nav-button[data-index="2"]:hover ~ #nav-content-highlight,
.nav-button[data-index="2"].activeItem ~ #nav-content-highlight {
  top: calc(8px + 2 * 62px);
}

.nav-button[data-index="3"]:hover ~ #nav-content-highlight,
.nav-button[data-index="3"].activeItem ~ #nav-content-highlight {
  top: calc(8px + 3 * 62px);
}

.nav-button[data-index="4"]:hover ~ #nav-content-highlight,
.nav-button[data-index="4"].activeItem ~ #nav-content-highlight {
  top: calc(8px + 4 * 62px);
}

.nav-button[data-index="5"]:hover ~ #nav-content-highlight,
.nav-button[data-index="5"].activeItem ~ #nav-content-highlight {
  top: calc(8px + 5 * 62px);
}

.nav-button[data-index="6"]:hover ~ #nav-content-highlight,
.nav-button[data-index="6"].activeItem ~ #nav-content-highlight {
  top: calc(8px + 6 * 62px);
}

.nav-button[data-index="7"]:hover ~ #nav-content-highlight,
.nav-button[data-index="7"].activeItem ~ #nav-content-highlight {
  top: calc(8px + 7 * 62px);
}

.nav-button[data-index="8"]:hover ~ #nav-content-highlight,
.nav-button[data-index="8"].activeItem ~ #nav-content-highlight {
  top: calc(8px + 8 * 62px);
}

.nav-button[data-index="9"]:hover ~ #nav-content-highlight,
.nav-button[data-index="9"].activeItem ~ #nav-content-highlight {
  top: calc(8px + 9 * 62px);
}

#nav-bar .fas {
  min-width: 3rem;
  text-align: center;
}
#nav-bar .side-icon svg {
  width: 16px;
  height: 16px;
}
#nav-bar .fas > svg {
  max-width: 20px;
  max-height: 20px;
  fill: var(--navbar-light-secondary);
}

#nav-footer {
  position: relative;
  width: var(--navbar-width);
  height: 58px;
  color: var(--navbar-light-secondary);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  transition: width 0.2s, height 0.2s;
  background-color: #fff;
  margin-bottom: 5px;
}

#nav-footer-heading {
  position: relative;
  // width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#nav-footer-avatar {
  position: relative;
  margin: 11px 0 11px 16px;
  left: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  transform: translate(0);
  transition: 0.2s;
}
#nav-footer-avatar img {
  height: 100%;
}

#nav-footer-titlebox {
  position: relative;
  margin-left: 16px;
  width: 10px;
  display: flex;
  flex-direction: column;
  transition: opacity 1s;
}

#nav-footer-subtitle {
  color: var(--navbar-light-secondary);
  font-size: 0.6rem;
}

#nav-toggle:not(:checked) ~ #nav-footer-toggle:checked + #nav-footer {
  height: 30%;
  min-height: 54px;
}
#nav-toggle:not(:checked)
  ~ #nav-footer-toggle:checked
  + #nav-footer
  label[for="nav-footer-toggle"] {
  transform: rotate(180deg);
}

label[for="nav-footer-toggle"] {
  position: absolute;
  right: 0;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s, opacity 0.2s;
}

#nav-footer-content {
  margin: 0 16px 16px 16px;
  border-top: solid 1px var(--navbar-light-secondary);
  padding: 16px 0;
  color: var(--navbar-light-secondary);
  font-size: 0.8rem;
  overflow: auto;
}
#nav-footer-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
#nav-footer-content::-webkit-scrollbar-thumb {
  border-radius: 99px;
}

#nav-toggle:checked ~ #nav-footer #nav-footer-content {
  display: none;
}

.remove-spin-wheel {
  .Modal__content {
    position: relative !important;
    margin: 7px;
  }
}

.start-again {
  .Modal__content {
    position: relative !important;
    margin: 7px;
  }
}

.libraryItem_content {
  .preview-card {
    position: relative;
    overflow: hidden;
    transition: transform 0.2s;
    padding: 0 !important;
    border-radius: 8px;
    // height: 260px !important;
  }

  .small-text {
    font-size: 12px;
    color: #8d8d8d;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .title-text {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  // .spin__wheel-preview-card {
  //   height: auto !important;
  // }

  .delete-checkbox {
    right: 5%;
    top: 1%;
    position: relative;
    .form-check-input {
      border: 1px solid rgb(196 49 75);
    }
    .form-check-input:checked {
      background-color: rgb(196 49 75);
    }
    margin: 0 5px;
  }
  .checkbox-title {
    font-size: 12px;
    color: black;
    padding: 3px;
    margin-left: 6px;
    .note {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .card-title {
    border-top: 1px solid #e9ecef;
    padding: 10px;
  }

  .public-badge {
    display: flex;
    justify-content: flex-end;
    background-color: rgba(189, 186, 186, 0.212);
    border-radius: 4px;
  }

  .invert-wheel {
    filter: invert(0.3);
  }

  .card-img-top {
    width: 100%;
    height: 260px;
    object-fit: contain;
  }

  .card-img-top-image {
    // width: 100%;
    height: 260px;
    width: 100%;
    // height: 100%;
    object-fit: contain;
  }

  .spin-card-img {
    width: 100%;
    object-fit: cover;
  }

  .card-img-top svg {
    width: 100%; /* Ensure the SVG scales to the container width */
    height: 260px; /* Ensure the SVG scales to the container height */
    object-fit: cover; /* Ensure the SVG covers the container */
  }

  .card-img {
    width: 100%;
    height: 200px;
    div {
      width: 100%;
      height: 100%;

      svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: rgba(177, 177, 185, 0.5);
    background: #f6f6f7;
    opacity: 0.95;
    // opacity: 0.5;
    color: var(--navbar-dark-primary);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    // transition: opacity 0.8s, transform 0.8s;
    transition: opacity 0.45s ease;
    transform: translateY(100%);
  }

  .preview-card:hover .card-overlay {
    opacity: 1;
    transform: translateY(0);
  }

  .card-body {
    padding: 10px;
    border-top: 1px solid #e9ecef;
  }

  .btn,
  .btn:hover {
    margin: 5px;
    // background-color: var(--navbar-dark-primary) !important;
    background-color: #4262ff;
    // color: var(--navbar-dark-secondary);
    color: #fff;
    border: none;
    // border-radius: 5px;
    border-radius: 999px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 13px;
  }
}

.popover-content {
  position: absolute;
  padding: 20px;
  transition: top 0.5s ease-in-out;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  bottom: 5%;
  width: 30%;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  left: 15%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .popover-content {
    width: 70%;
  }
}

.font-weight-bold {
  font-weight: bold;
}

.create-btn {
  background-color: #494b83 !important;
  border-color: #494b83 !important;
  color: white !important;
}

.popover-heading {
  display: flex;
  justify-content: space-between;
  .close {
    cursor: pointer;
    margin: 5px !important;
    height: 20px !important;
    width: 20px !important;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.sc-gEvEer {
  border: none !important;
  top: -1175% !important;
  border-radius: 8px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: blur(12px) !important;
  padding: 15px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.sc-eqUAAy {
  border: none !important;
  border-radius: 4px !important;
  padding: 4px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.sc-aXZVg {
  min-height: 28px !important;
  height: 28px !important;
  width: 30px !important;
  margin-top: 3px !important;

  div > svg {
    font-size: 20px !important;
  }
}

.category_color_picker .main_div {
  top: -252%;
  left: 110%;

  .custom-color-picker > span {
    margin-left: 2px;
  }
}

.template-preview-btn {
  background-color: #494b83 !important;
  border-color: #494b83 !important;
  color: white !important;
  width: fit-content !important;
}
.template-preview-btn:hover {
  background-color: #494b83 !important;
  border-color: #494b83 !important;
  color: white !important;
}

.delete-icon {
  height: 20px;
  width: 20px;
  margin-bottom: 6px;
  cursor: pointer;
}

.template-icon-btn {
  background-color: #fff !important;
  border: 1px solid #494b83 !important;
  color: #000 !important;
  width: fit-content !important;

  svg {
    height: 15px;
    width: 15px;
  }
}

.template-video-icon-btn {
  background-color: #fff !important;
  color: #000 !important;
  width: fit-content !important;
  margin: 0px !important;

  svg {
    height: 20px;
    width: 20px;
  }
}

.category-btn svg {
  color: #000;
}

.template-delete-icon {
  background-color: #fff !important;
  color: #c4314b !important;
  border: 1px solid #c4314b !important;
  width: fit-content !important;

  svg {
    height: 15px;
    width: 15px;
  }
}

.audio-delete-icon {
  background-color: #fff !important;
  color: #c4314b !important;
  width: fit-content !important;
  cursor: pointer !important;

  svg {
    height: 20px;
    width: 20px;
  }
}

.btn-remove {
  border-color: red !important;
}

.active-preview-card {
  border: 2px solid #494b83 !important;
}
.delete-preview-card {
  border: 2px solid rgb(196, 49, 75);
}

.template-icon-btn:hover {
  background-color: #fff !important;
  border: 1px solid #494b83 !important;
  color: #000 !important;
}
.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  color: white;
  top: 10%;
  cursor: pointer;
  &:hover {
    background-color: black !important;
  }
}

.library-menu-items-container {
  .acv-tooltip-wrapper {
    font-size: 14px !important;
  }

  .library-actions {
    display: flex;
    justify-content: space-between;
    gap: 8px !important;

    button .library-actions-counter {
      position: absolute;
      right: 2px;
      bottom: 2px;
      border-radius: 50%;
      width: 1.3em;
      height: 1.3em;
      padding: 1px;
      font-size: 0.7rem;
      background: #fff;
    }

    .ToolIcon_type_button .ToolIcon__icon svg {
      height: 20px;
      width: 20px;
      color: #fff;
    }

    .library-actions-remove {
      background-color: $oc-red-8 !important;
    }

    &--cancel {
      width: auto !important;
      background-color: $oc-red-8 !important;
      &:active {
        background-color: $oc-red-9 !important;
      }
      svg {
        color: $oc-white !important;
        // height: 20px;
        width: 20px;
      }
      .library-actions-counter {
        color: $oc-red-7;
      }
    }

    &--remove {
      height: 35px !important;
      width: 35px !important;
      cursor: pointer !important;
      background-color: inherit !important;
      // background-color: $oc-red-7 !important;
      &:hover {
        // background-color: $oc-red-8 !important;
      }
      &:active {
        // background-color: $oc-red-9 !important;
      }
      svg {
        color: $oc-white !important;
        // height: 20px;
        width: 20px;
      }
      .library-actions-counter {
        color: $oc-red-7;
      }
    }

    &--removed {
      cursor: pointer !important;
      height: 35px !important;
      background-color: $oc-red-7 !important;
      &:hover {
        background-color: $oc-red-8 !important;
      }
      &:active {
        background-color: $oc-red-9 !important;
      }
      svg {
        color: $oc-white !important;
        height: 20px;
        width: 20px;
      }
      .library-actions-counter {
        color: $oc-red-7;
      }
    }

    &--export {
      height: 35px !important;
      width: 35px !important;
      cursor: pointer !important;
      background-color: inherit !important;
      // background-color: $oc-lime-5 !important;

      &:hover {
        // background-color: $oc-lime-7 !important;
      }

      &:active {
        // background-color: $oc-lime-8 !important;
      }
      svg {
        color: $oc-white !important;
      }
      .library-actions-counter {
        color: $oc-lime-5;
      }
    }

    &--publish {
      font-size: 15px;
      height: 35px !important;
      width: 35px !important;
      cursor: pointer !important;
      background-color: inherit !important;
      // background-color: $oc-cyan-6 !important;
      &:hover {
        // background-color: $oc-cyan-7 !important;
      }
      &:active {
        background-color: $oc-cyan-9 !important;
      }
      svg {
        color: $oc-white !important;
      }
      label {
        margin-left: -0.2em;
        margin-right: 1.1em;
        color: $oc-white;
        font-size: 18px;
      }
      .library-actions-counter {
        color: $oc-cyan-6;
      }
    }

    &--load {
      height: 35px !important;
      width: 35px !important;
      cursor: pointer !important;
      background-color: inherit !important;
      // background-color: $oc-blue-6 !important;
      &:hover {
        // background-color: $oc-blue-7 !important;
      }
      &:active {
        // background-color: $oc-blue-9 !important;
      }
      svg {
        color: $oc-white !important;
      }
    }

    &--upload {
      height: 35px !important;
      cursor: pointer !important;
      // background-color: $oc-blue-6 !important;
      background-color: #494b83 !important;
      &:hover {
        // background-color: $oc-blue-7 !important;
        background-color: #494b83 !important;
      }
      &:active {
        // background-color: $oc-blue-9 !important;
        background-color: #494b83 !important;
      }
      svg {
        color: $oc-white !important;
      }
    }
  }
  &__items {
    max-height: 50vh;
    overflow: auto;
    margin-top: 0.5rem;
  }

  .separator {
    font-weight: 500;
    font-size: 0.9rem;
    margin: 0.6em 0.2em;
    color: var(--text-primary-color);
  }
}
.assignBtn {
  background-color: #a6a6a6 !important;
  color: #000 !important;
  border: none;
  border-radius: 5px;
  padding: 5px 30px 5px 5px !important;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 15px !important;
}

.tab-button {
  width: 150px !important;
  color: #000;
}

div.libraryItem_content .nav-tabs .nav-link {
  border: none !important;
  background-color: #fff !important;
}

div.libraryItem_content .nav .active {
  background-color: #fff !important;
  border: none !important;
  color: #000 !important;
  max-width: 190px;
  font-weight: 800 !important;
  cursor: pointer;
}

div.libraryItem_content .nav-tabs .nav-link:focus,
.nav-link:hover {
  color: #000 !important;
}

div.libraryItem_content .nav-tabs {
  border-bottom: 2px solid #dee2e6;
}

div.libraryItem_content .nav-tabs .nav-link {
  margin-bottom: 0px !important;
}

.acvLibraryPreviewDialog .back-icon {
  height: 24px;
  width: 20px;
}

.acvLibraryPreviewDialog button:hover {
  background-color: #dbdbdb !important;
}

.acvLibraryPreviewDialog .preview-title {
  margin-bottom: 50px !important;
}

.acvLibraryPreviewDialog .top-section {
  height: 430px;
}

div.acv-previewDialog .previewDialog {
  position: absolute;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  z-index: 1050 !important;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 48%;
  right: 2%;
  bottom: 36%;
  border-radius: 15px;
}

.dialogBox-div {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px;
}

div.acvLibraryPreviewDialog .language-selector select {
  width: 157px;
  height: 36px;
  border: #cdccd7 1px solid;
  border-radius: 4px;
  margin-top: 0px 0px;
  padding: 5px 15px;
}

.zoomIn-Out-icon .icon {
  width: 30px !important;
}

.about-section {
  gap: 160px;
}

.about-section .right-author-section {
  flex: 0 0 290px;
}

.right-icon {
  width: 30px;
  margin-top: 10px;
}

.about-section .author-img {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.preview-template-card {
  min-height: 210px;
  display: flex;
  flex-direction: column;
  flex: 0 0 200px;
  margin-right: 20px;
  margin-bottom: 40px;
}

.preview-template-img {
  border-radius: 10px;
}

.preview-badge {
  border: 1px solid #d1d4db !important;
  border-radius: 999px !important;
  padding: 4px 9px !important;
}

.preview-button-div {
  padding-top: 44px;
  padding-bottom: 40px;
  border-top: 1px solid #ebebef;
  border-bottom: 1px solid #ebebef;
}

.search-input {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-color: #f1f1f1;
}

.form-control:focus {
  border-color: #dce3eb;
  box-shadow: none;
}

// .preview-template-card .card {
//   position: relative;
//   width: 300px;
//   height: 200px;
//   background-color: #f2f2f2;
//   border-radius: 10px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   overflow: hidden;
//   perspective: 1000px;
//   box-shadow: 0 0 0 5px #ffffff80;
//   transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
// }

// .preview-template-card:hover {
//   transform: scale(1.05);
//   box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
// }

.card__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f2f2f2;
  transform: rotateX(-90deg);
  transform-origin: bottom;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}

.card:hover .card__content {
  transform: rotateX(0deg);
}

.card__title {
  margin: 0;
  font-size: 24px;
  color: #333;
  font-weight: 700;
}

.card__description {
  margin: 10px 0 0;
  font-size: 14px;
  color: #777;
  line-height: 1.4;
}

.tpicker-preview-base-trigger {
  border-radius: 8px;
  background-color: #f0f2ff;
  color: #455bed;
  display: block;
  padding: 16px 24px 20px 16px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
}

.tpicker-preview-base-trigger__cta {
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-family: Roobert, sans-serif;
  font-weight: 500;
  padding: 0;
  margin-bottom: 4px;
}

.tpicker-preview-base-trigger__text {
  font-family: Roobert, sans-serif;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: #5f5c80;
  display: block;
  text-decoration: none;
}
.tpicker-preview-base-trigger__text:hover {
  text-decoration: none !important;
}

.details-f1sod:hover {
  opacity: 1;
}

.details__description {
  color: #000;
  overflow: hidden;
  width: calc(100% - 24px - 8px);
  max-height: 72px;
  display: flex;
  flex-flow: column wrap;
  font-size: 14px;
}

.details__description_mobile {
  color: #000;
  // margin-top: 30px;
  overflow: hidden;
  width: 300px;
  max-height: 72px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-flow: column wrap;
  font-size: 14px;
}

#sliderSpinner .active {
  max-width: 100% !important;
  background-color: white !important;
}

#sliderSpinner .carousel-control-next,
.carousel-control-prev {
  width: 7%;
}

.library-item-dialog > .popover {
  border-radius: 12px;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
  background: rgb(255, 255, 255) !important;
  backdrop-filter: unset !important;
  top: -50% !important;
  left: 0 !important;
  transform: translate(-50%, -100%);
  padding: 15px !important;
}

@media (min-width: 984px) and (max-width: 1223px) and (max-height: 728px) {
  .custom-category {
    position: relative !important;
  }
}

@media (min-width: 1401px) and (max-width: 1697px) and (max-height: 728px) {
  .custom-category {
    position: relative !important;
  }
}

@media (min-width: 1401px) and (max-width: 1697px) and (max-height: 786px) {
  .custom-category {
    position: relative !important;
  }
}

@media (min-width: 992px) and (max-width: 1205px) and (max-height: 786px) {
  .custom-category {
    position: relative !important;
  }
}

@media (min-width: 1401px) and (max-width: 1697px) and (max-height: 888px) {
  .custom-category {
    position: relative !important;
  }
}

@media (min-width: 1401px) and (max-width: 1700px) {
  .custom-category {
    position: relative !important;
  }
}

@media (min-width: 991px) and (max-width: 1205px) {
  .custom-category {
    position: relative !important;
  }
}
