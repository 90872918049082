#carouselExampleIndicators {
  button:hover {
    background-color: transparent !important;
  }
  button:focus {
    box-shadow: none !important;
  }
}

.carousel {
  .carousel-control-prev {
    :hover {
      background-color: transparent !important;
    }
    :focus {
      box-shadow: none !important;
    }
    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.7409 7.21957V16.7896C20.7409 18.7496 18.6109 19.9796 16.9109 18.9996L12.7609 16.6096L8.61094 14.2096C6.91094 13.2296 6.91094 10.7796 8.61094 9.79957L12.7609 7.39957L16.9109 5.00957C18.6109 4.02957 20.7409 5.24957 20.7409 7.21957Z' fill='%23292D32'/%3E%3Cpath d='M4.26172 18.9303C3.85172 18.9303 3.51172 18.5903 3.51172 18.1803V5.82031C3.51172 5.41031 3.85172 5.07031 4.26172 5.07031C4.67172 5.07031 5.01172 5.41031 5.01172 5.82031V18.1803C5.01172 18.5903 4.67172 18.9303 4.26172 18.9303Z' fill='%23292D32'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
    }
  }
  .carousel-control-next {
    :hover {
      background-color: transparent !important;
    }
    :focus {
      box-shadow: none !important;
    }
    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.26172 7.21957V16.7896C4.26172 18.7496 6.39172 19.9796 8.09172 18.9996L12.2417 16.6096L16.3917 14.2096C18.0917 13.2296 18.0917 10.7796 16.3917 9.79957L12.2417 7.39957L8.09172 5.00957C6.39172 4.02957 4.26172 5.24957 4.26172 7.21957Z' fill='%23292D32'/%3E%3Cpath d='M20.7383 18.9303C20.3283 18.9303 19.9883 18.5903 19.9883 18.1803V5.82031C19.9883 5.41031 20.3283 5.07031 20.7383 5.07031C21.1483 5.07031 21.4883 5.41031 21.4883 5.82031V18.1803C21.4883 18.5903 21.1583 18.9303 20.7383 18.9303Z' fill='%23292D32'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
    }
  }
}
