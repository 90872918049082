@import "../css/variables.module";

.acv {
  .icon-small {
    --focus-highlight-color: #0000000;
    .ToolIcon__icon {
      width: auto;
      height: auto;
    }
    .ToolIcon__icon svg {
      height: 1rem;
    }
  }
  .icon-small.ToolIcon:active {
    background-color: transparent;
  }
  .icon-small.ToolIcon:hover {
    background-color: transparent;
  }
  .CollabButton,
  .collabIslandBtn {
    background-color: transparent !important;
  }
  .CollabButton.ToolIcon {
    border-radius: 1rem;
    .ToolIcon__icon {
      border-radius: 1rem;
      background-color: #494b83;
      width: 2rem;
      height: 2rem;
    }
    .ToolIcon__icon svg {
      height: 1em;
    }
  }
  .CollabButton.is-collaborating {
    background-color: var(--button-special-active-bg-color);
    .ToolIcon__icon svg,
    .ToolIcon__label {
      color: var(--icon-green-fill-color);
    }
  }

  .CollabButton-collaborators {
    :root[dir="ltr"] & {
      // right: -5px;
      right: -10px;
      min-width: 22px;
    }
    :root[dir="rtl"] & {
      // left: -5px;
      left: -10px;
      min-width: 22px;
    }
    min-width: 1em;
    position: absolute;
    bottom: -5px;
    padding: 3px;
    border-radius: 50%;
    background-color: $oc-green-6;
    color: $oc-white;
    font-size: 0.7em;
    font-family: var(--ui-font);
  }

  @media (max-width: 730px) {
    .CollabButton,
    .collabIslandBtn {
      background-color: transparent !important;
    }
  }
}
