.category_popover {
  .popover {
    top: 0 !important;
    left: 0;
    /* width: 100%; */
    transform: translate(15%, 0) !important;
    max-height: 100%;
    color: #000;
    display: flex;
    padding: 6px 12px;
    width: 70%;

    .category_wrapper {
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      color: #3a3838;
      transition: all 0.2s ease-in-out;
      padding: 6px;
      border-radius: 12px;
      margin-bottom: 2px !important;
      &:hover {
        background: #e9ecef;
        transition: top 0.2s;
      }

      .category_name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .filter-checkbox {
      appearance: none;
      width: 15px;
      height: 15px;
      border: 1px solid #ccc;
      border-radius: 3px;
      position: relative;
      cursor: pointer;
    }

    .filter-checkbox:checked {
      background-color: #494b83; /* Change this to your desired color */
      border: none;
    }

    .filter-checkbox:checked::after {
      content: "";
      position: absolute;
      top: 2px;
      left: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    .filter-submit-btn {
      background-color: #494b83 !important;
      color: white !important;
      border: none;
      border-radius: 5px;
      padding: 5px 10px !important;
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
    }

    .filter-cancel-btn {
      background-color: #f8f9fa !important;
      color: #494b83 !important;
      border: none;
      border-radius: 5px;
      padding: 5px 10px !important;
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
    }

    .category_search {
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 5px !important;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      width: 100%;
      margin: 5px;
    }

    input[type="checkbox"]:disabled {
      background-color: lightgrey !important;
    }

    .filter-submit-btn > .category_loader > .spinner-border {
      width: 1rem;
      height: 1rem;
    }
  }

  @media screen and (max-width: 1800px) {
    .popover {
      width: 84%;
      height: 65%;
    }
  }
}
