.main-div {
  position: absolute;
  top: 40%;
  left: 50%;
  background: white;
  padding: 6px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 100;
}
.main_div {
  position: absolute;
  top: 42%;
  left: 50%;
  background: white;
  padding: 6px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 100;
}

.custom-color-picker {
  display: flex;
  align-items: center;
  gap: 4px;
}
.default-colors {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.default-colors button {
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
}

.image-color-picker {
  width: 20px;
  height: 20px;
}

.color_picker {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: 0 solid transparentize(white, 0.75);
  background: white;
  border-radius: 4px;
  position: absolute;
  top: 107%;

  :root[dir="ltr"] & {
    left: 0.5px;
  }

  :root[dir="rtl"] & {
    right: -5.5px;
  }
}

.color-picker-content .color-input-container {
  grid-column: 1 / span 5;
}
.color-input-container:focus-within .color-picker-hash {
  box-shadow: 0 0 0 2px black;
}

.color-input-container:focus-within .color-picker-hash::before,
.color-input-container:focus-within .color-picker-hash::after {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
}

.color-input-container:focus-within .color-picker-hash::before {
  background: #2e2e2e;

  :root[dir="ltr"] & {
    right: -1px;
  }

  :root[dir="rtl"] & {
    left: -1px;
  }
}

.color-input-container:focus-within .color-picker-hash::after {
  background: white;

  :root[dir="ltr"] & {
    right: -2px;
  }

  :root[dir="rtl"] & {
    left: -2px;
  }
}

.color-input-container {
  display: flex;
  // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
}

.color-picker-input {
  width: 17.2ch; /* length of `transparent` */
  margin: 0;
  font-size: 1rem;
  background-color: white;
  color: darkgray;
  border: 0;
  outline: none;
  height: 1.75em;
  padding-left: 25px !important;

  :root[dir="ltr"] & {
    border-radius: 0 4px 4px 0;
  }

  :root[dir="rtl"] & {
    border-radius: 4px 0 0 4px;
  }

  float: left;
  padding: 1px;
  padding-inline-start: 0.5em;
  appearance: none;
}

.color-picker-box {
  position: relative;
  height: 1.875rem;
  width: 1.875rem;
  cursor: pointer;
  border-radius: 4px;
  margin: 0;
  box-sizing: border-box;
  border: 1px solid #ddd !important;
  filter: "var(--theme-filter)";

  &:focus {
    /* TODO: only show the border when the color is too light to see as a shadow */
    box-shadow: 0 0 4px 1px currentColor;
    border-color: blue;
  }
}
