.rename-workspace {
  .Modal__content {
    width: 580px !important;
  }
}

.workspace-container {
  display: flex;
  flex-direction: column;
}

.name-input-container {
  margin: 0.8rem 0;
  width: 100%;
}

.name-input {
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.workspace_name {
  margin-bottom: 0;
}

.button-container {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.save-button {
  background-color: #585a96 !important; /* Green */
  color: white;
  border: none;
  padding-left: 15px !important;
  padding-right: 15px !important;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.cancel-button {
  color: #f44336;
  border: none;
  padding-left: 15px !important;
  padding-right: 15px !important;
  cursor: pointer !important;
}
.save-spinner {
  border-width: 2px;
}

.disabled-button {
  color: #ffffff !important;
  background-color: #9d9d9d !important;
  cursor: not-allowed !important;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  cursor: pointer;

  input {
    border: none;
  }
}

.save-date {
  background-color: #494b83 !important;
  color: white !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  padding: 3px 16px !important;
  display: flex !important;
  align-items: center !important;
  gap: 6px !important;
}

.btn-save-date-div {
  display: flex;
  justify-content: flex-end;
  padding: 0px 16px 8px 8px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 6px;
}

.tag {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
}

.remove-tag {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-left: 8px !important;
}

.remove-tag:hover {
  background: none !important;
}

.modal-backdrop {
  display: none !important;
}

.footer {
  bottom: 0;
  padding: 15px 0;
  margin-left: 15px;
}

.infoBtn {
  padding: 4px 16px !important;
  border: 1px solid #585a96 !important;
  margin-left: 5px !important;
}
.infoBtn:hover {
  background-color: #585a96 !important;
  color: #ffffff !important;
}

.filter-drawer {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: none;
  background: white;
}

.custom-select{
  padding: 5px 15px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--island-bg-color);
}
