@import "../css/variables.module";

.acv {
  .single-library-item {
    position: relative;
    display: flex;

    &__svg {
      width: 7.5rem;
      height: 7.5rem;
      border: 1px solid var(--button-gray-2);
      margin: 0.3rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .ToolIcon__icon {
      background-color: $oc-white;
      width: auto;
      height: auto;
      margin: 0 0.5rem;
    }
    .ToolIcon,
    .ToolIcon_type_button:hover {
      background-color: white;
      right: 0;
    }
    .required,
    .error {
      color: $oc-red-8;
      font-weight: bold;
      font-size: 1rem;
      margin: 0.2rem;
    }
    .error {
      font-weight: 500;
      margin: 0;
      padding: 0.3em 0;
    }

    &--remove {
      position: absolute;
      top: 0.2rem;
      right: 1.3rem;

      .ToolIcon__icon {
        margin: 0;
      }
      .ToolIcon__icon {
        background-color: $oc-red-6;
        &:hover {
          background-color: $oc-red-7;
        }
        &:active {
          background-color: $oc-red-8;
        }
      }
      svg {
        color: $oc-white;
        padding: 0.26rem;
        border-radius: 0.3em;
      }
    }
  }
}

.single-library-item--remove .ToolIcon__icon {
  width: 20px !important;
  height: 20px !important;
}

.error {
  color: red !important;
  position: absolute !important;
  bottom: 25px !important;
  left: 25px !important;
  transform: translateY(100%) !important;
}
