.container-elements {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.container-video {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
