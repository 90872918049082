.my-workspace {
  .nav-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
  }
  .card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    gap: 16px;
    margin: 0 auto;
  }

  .filter-container {
    height: 34px !important;
    margin-bottom: 10px;
  }

  .workspace-card {
    background-color: #fff;
    border: 1px solid #0000000d;
    border-radius: 8px;
    box-shadow: rgba(245, 245, 245, 0.5) 0px 0px 20px 0px;
    margin: 8px;
    font-family: Arial, sans-serif;

    .live-container {
      display: flex;
      align-items: center;
    }

    .live-pill {
      display: flex;
      align-items: center;
      grid-gap: 5px;
      gap: 0px;
      background-color: rgba(96, 125, 59, 0.15);
      border-radius: 20px;
      padding: 2px 8px;
      animation: blink 1.5s infinite;
    }

    .live-dot {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      animation: pulse 1.5s infinite;
      border-radius: 50%;
      margin-right: 8px;
    }

    .live-text {
      font-size: 12px;
      font-weight: bold;
      color: #607d3b;
      white-space: nowrap;
    }

    /* Dot Pulsing Animation */
    @keyframes pulse {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      50% {
        transform: scale(1.5);
        opacity: 0.5;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    /* Text Blinking Animation */
    @keyframes blink {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }

    .title-wrap {
      display: flex;
      align-items: center;
      padding: 16px;
    }

    .name-dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #767676;
      margin-right: 8px;
    }

    .title {
      flex-grow: 1;
      font-size: 16px;
      font-weight: bold;
      color: #333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .Action {
      rotate: 90deg;
      cursor: pointer;
    }

    .thumbnail img {
      object-fit: cover;
      height: 170px;
      width: 100%;
    }

    .footer-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      border-top: 1px solid #0000000d !important;
    }

    .profile {
      width: 32px;
      height: 32px;
      background-color: #6264a7;
      margin-right: 8px;
      border-radius: 50%;
      color: #fff;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .date {
      display: flex;
      align-items: center;
      color: #666;
    }

    .popover {
      left: 84%;
      background: #fff;
      border: 1px solid #22242626;
      border-radius: 0.28571429rem;
      box-shadow: 0 2px 3px 0 #22242626;

      .item:hover {
        background-color: #0000000d;
        cursor: pointer;
      }
    }
  }

  .card {
    border: none !important;
  }

  .card-body {
    margin: 0% 0% 0% 3%;
    padding: 6% 0%;
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .my-workspace .card-container {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    gap: 12px;
  }

  .my-workspace .workspace-card .title {
    font-size: 14px;
  }

  .my-workspace .workspace-card .footer-wrap {
    // flex-direction: column;
    align-items: flex-start;
  }

  .my-workspace .workspace-card .profile {
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 768px) {
  .my-workspace .card-container {
    grid-template-columns: repeat(auto-fill, minmax(203px, 1fr));
    gap: 8px;
  }

  .my-workspace .workspace-card .thumbnail img {
    height: 140px;
  }

  .my-workspace .workspace-card .footer-wrap {
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .my-workspace .card-container {
    grid-template-columns: 1fr;
  }

  .my-workspace .workspace-card .thumbnail img {
    height: 120px;
  }

  .my-workspace .workspace-card .footer-wrap {
    flex-direction: column;
    gap: 8px;
  }

  .workspace-card {
    width: 56%;
  }

  .my-workspace .filter-container {
    margin-bottom: 110px !important;
  }
}

@media (max-width: 362px) {
  .workspace-card {
    width: 55%;
    left: -8%;
    position: relative;
  }

  .my-workspace .filter-container {
    margin-bottom: 110px !important;
  }

  #nav-header {
    left: 20px;
  }
  .nav-button {
    margin: 8px 16px 8px -5px;
  }
}

@media (max-width: 375px) {
  .workspace-card {
    width: 55%;
    left: -8%;
    position: relative;
  }

  .my-workspace .filter-container {
    margin-bottom: 110px !important;
  }

  #nav-header {
    left: 20px;
  }
  .nav-button {
    margin: 8px 16px 8px -5px;
  }
}
