.topic-picker {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  text-align: center;
  height: 266.67px;

  .card-header-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .error_msg {
    color: red;
    font-size: 13px;
    align-items: start;
    display: flex;
  }

  .topic-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 1rem;
    top: 12%;
    position: relative;

    &:focus {
      outline: none;
      border-color: #494b83;
    }
  }

  .topics-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-content: flex-start; /* Ensures wrapped items stay close together */
    height: 110px;
    overflow-y: auto; /* Enables vertical scrolling */
  }

  .topic-label {
    font-size: 14px;
    align-items: start;
    display: flex;
    color: #000;
    position: relative;
    top: 10%;
  }

  .topic-chip {
    display: flex;
    align-items: center;
    background: #494b83;
    color: #fff;
    padding: 2px 7px;
    border-radius: 20px;
    font-size: 12px;
    height: fit-content;

    .remove-button {
      background: transparent;
      border: none;
      color: #fff;
      margin-left: 8px;
      cursor: pointer;
      font-size: 1.2rem;
      line-height: 1;
      padding: 0px;
      margin-top: 0px;

      &:hover {
        color: #ff5a5a;
      }
    }
  }
}

.topic_label {
  font-size: 14px;
  align-items: start;
  display: flex;
  color: #000;
  position: relative;
  top: 0%;
}

.topic_input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1rem;
  top: 0%;
  position: relative;

  &:focus {
    outline: none;
    border-color: #494b83;
  }
}
