@import "open-color/open-color";

.acv {
  ::-webkit-scrollbar {
    width: 0;
  }
  .pl-0 {
    padding-left: 0 !important;
  }
  .mr-0 {
    margin-right: 0 !important;
  }
  .layer-ui__library {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .layer-ui__library-header {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 2px 0;

      button {
        // 2px from the left to account for focus border of left-most button
        margin: 0 2px;
      }

      a {
        margin-inline-start: auto;
        // 17px for scrollbar (needed for overlay scrollbars on Big Sur?) + 1px extra
        padding-inline-end: 18px;
        white-space: nowrap;
      }
    }
  }
  .layer-ui__library-message {
    padding: 10px 20px;
    max-width: 200px;
  }

  .layer-ui__library-items {
    max-height: 50vh;
    overflow: auto;
  }

  .layer-ui__wrapper {
    z-index: var(--zIndex-layerUI);

    &__top-right {
      display: flex;
    }

    &__footer {
      width: 100%;

      &-right {
        z-index: 100;
        display: flex;
      }
    }

    .fit-content {
      height: fit-content;
    }
    // .zen-mode-transition::-webkit-scrollbar {
    //   width: 0;
    // }
    .zen-mode-transition {
      overflow: auto;
      transition: transform 0.5s ease-in-out;
      height: 100%;
      width: 100%;

      :root[dir="ltr"] &.transition-left {
        transform: translate(-999px, 0);
      }

      :root[dir="ltr"] &.transition-right {
        transform: translate(999px, 0);
      }

      :root[dir="rtl"] &.transition-left {
        transform: translate(999px, 0);
      }

      :root[dir="rtl"] &.transition-right {
        transform: translate(-999px, 0);
      }

      :root[dir="ltr"] &.layer-ui__wrapper__footer-left--transition-left {
        transform: translate(-76px, 0);
      }
      :root[dir="rtl"] &.layer-ui__wrapper__footer-left--transition-left {
        transform: translate(76px, 0);
      }

      &.layer-ui__wrapper__footer-left--transition-bottom {
        transform: translate(0, 92px);
      }
    }

    .disable-zen-mode {
      height: 30px;
      position: absolute;
      bottom: 10px;
      [dir="ltr"] & {
        right: 15px;
      }
      [dir="rtl"] & {
        left: 15px;
      }
      font-size: 10px;
      padding: 10px;
      font-weight: 500;
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s linear 0s, opacity 0.5s;

      &--visible {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s linear 300ms, opacity 0.5s;
        transition-delay: 0.8s;
      }
    }

    .layer-ui__wrapper__footer-center {
      pointer-events: none;
      & > * {
        pointer-events: all;
      }
    }
    .layer-ui__wrapper__footer-left,
    .layer-ui__wrapper__footer-right,
    .disable-zen-mode--visible {
      pointer-events: all;
    }

    .layer-ui__wrapper__footer-left {
      margin-bottom: 0.2em;
    }

    .layer-ui__wrapper__footer-right {
      margin-top: auto;
      margin-bottom: auto;
      margin-inline-end: 1em;
    }
  }
  .saveBtnIsland,
  .collabBtnIsland {
    margin: auto;
  }
  .divider {
    border-left: 1px solid rgba(95, 95, 95, 0.5);
  }
}
.settings-icon {
  right: -8px;
  top: 255px;
  background-color: var(--button-gray-1);
  border-radius: 20px 0 0 20px;
}

.library_icon {
  right: -8px;
  top: 150px;
  background-color: var(--button-gray-1);
  border-radius: 20px 0 0 20px;
}

.notes-icon {
  right: -8px;
  top: 200px;
  background-color: var(--button-gray-1);
  border-radius: 20px 0 0 20px;
  .tool-button {
    padding: 0;
    border-radius: 20px 0 0 20px !important;
  }
  .ToolIcon_type_button {
    border-radius: 20px 0 0 20px !important;
  }

  .popover-mobile {
    position: absolute;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    z-index: 1;
    left: -5px;
    top: 50%;
    transform: translate(-100%, -50%);

    div {
      padding-right: 15px;
      padding-left: 5px;
    }

    div:hover {
      background-color: #494b83;
      cursor: pointer;
      color: white;
    }

    .button:hover {
      background-color: none !important;
    }

    div:hover .ToolIcon__icon svg {
      fill: #fff;
    }
  }
}
.layer-ui__wrapper {
  .FixedSideContainer.FixedSideContainer_side_top {
    .App-menu.App-menu_top > .Stack_vertical {
      width: 54%;
    }

    .popover {
      position: absolute;
      background-color: #fff;
      border-radius: 8px;
      // padding: 16px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
      z-index: 100;
      left: 0;
      top: 50%;
      transform: translate(-100%, -50%);

      .notes {
        padding-right: 15px;
        padding-left: 5px;
      }

      .notes:hover {
        background-color: #494b83;
        cursor: pointer;
        color: white;
      }

      .button:hover {
        background-color: none !important;
      }

      .notes:hover .ToolIcon__icon svg {
        fill: #fff;
      }

      .disabled {
        opacity: 0.5; /* Dim the appearance */
        cursor: not-allowed; /* Show not-allowed cursor */
      }

      .disabled:hover {
        background-color: transparent !important;
        color: #000;
      }
    }

    .gpt-dialog {
      border: 1px solid #0000006e;
    }
  }
}

.App-menu {
  .math-formula-container {
    .math-modal-wrapper {
      padding: 16px 40px;
      background-color: #fff;
      border-radius: 12px;

      .modal-header {
        font-weight: 500;
      }
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.5);

    .save-formula-btn {
      width: fit-content;
      padding: 3px 15px;
      margin: 0 auto;
      display: block;
    }

    .closeIcon {
      height: 23px;
      width: 23px;
      right: 10px;
      top: 15px;
      z-index: 99;
      background-color: #fff;
    }
    .math-modal-layer {
      position: absolute;
      margin-bottom: 8px;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: -1;
    }
  }
}

.mobile-menu {
  .cancel-btn {
    background-color: red !important;
  }
  .math-formula-container {
    .math-modal-wrapper {
      padding: 16px 40px;
      background-color: #fff;
      border-radius: 12px;

      .modal-header {
        font-weight: 500;
      }
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.5);

    .save-formula-btn {
      width: fit-content;
      padding: 3px 15px;
      margin: 0 auto;
      display: block;
    }

    .closeIcon {
      height: 23px;
      width: 23px;
      right: 10px;
      top: 15px;
    }
    .math-modal-layer {
      position: absolute;
      margin-bottom: 8px;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: -1;
    }
  }
}
.acv {
  .Avatar {
    justify-self: right;
    text-transform: uppercase;
    /* prettier-ignore */
    // background: linear-gradient(
    //   random(45)deg,
    //   #ee7752,
    //   #e73c7e,
    //   #23a6d5,
    //   #23d5ab
    // ) !important;
    background-size: 100% 200% !important;
  }
  @media (max-height: 736px) {
    .vScroll {
      // REMOVE COMMENT WHEN MORE FEATURES ARE AVAILABLE ON VERTICLE ACTION BAR
      // overflow-y: scroll;
      height: 100vh;
    }
  }
  @media (max-width: 352px) {
    .hScroll {
      width: 100vw !important;
      overflow-x: scroll;
    }
  }
  @media (max-width: 1280px) {
    .vScroll {
      height: 100vh;
      overflow: initial;
    }
  }
  .sidebarWrapper {
    top: -3px;
    // width: 240px !important;
    width: 15rem;
    padding-left: 27px;
    padding-right: calc(27px - 0.75rem);
    position: absolute;
    height: 100vh;
    z-index: 1;
    --island-bg-color: rgba(255, 255, 255, 1);
    background-color: var(--island-bg-color);
    // border-right: 1px solid #e9ecef;
    box-shadow: var(--shadow-island);
    box-shadow: 2px 0px 0px #dfdfdf;
    transition: width 0.2s ease;
    overflow-y: auto;
    scrollbar-width: none;
    overflow-x: hidden;
  }
  .wnone {
    width: 0px !important;
    padding: 0px !important;
  }
  &.theme--dark {
    .sidebarWrapper {
      --island-bg-color: rgba(30, 30, 30, 1);
    }
  }
  .right-side-panel {
    align-items: flex-start;
    gap: 15px;
    z-index: 99;
  }
}

.sidebar {
  position: absolute;
  bottom: 20px;
  right: 94px;
  border: 1px dashed gainsboro;
  text-align: end;
  transition: right 0.3s ease-in-out;
  // z-index: 1000;
}

.sidebar.open {
  // right: 0;
  // right: -26%;
  right: -1000%;
}

.arrow-button {
  display: flex;
  align-items: center;
  background: none !important;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  transition: background-color 0.3s;
  position: absolute;
  bottom: 47px;
  right: 70px;
  z-index: 1;
  box-shadow: none !important;
}

.arrow-button:hover {
  background-color: #253c7e;
}

.arrow-icon {
  display: inline-block;
  background-color: #ffffff;
  color: #494b83;
  padding: 7px;
  border-radius: 0 5px 5px 0;
  transition: background-color 0.3s, transform 0.3s;
}

.arrow-icon.open {
  transform: rotate(180deg);
}

.fixedBtmLsn {
  padding: 10px;
  border: 1px dashed gainsboro;
  text-align: end;
}

.pin {
  width: fit-content;
  cursor: pointer;
}

.save-formula-btn {
  .ToolIcon__icon {
    height: 34px;
    width: 80px;
  }
}

.cancel-btn {
  .ToolIcon__icon {
    background-color: #fa5252;
    color: white !important;
    height: 34px;
    width: 80px;
  }
}

.confirmationModal {
  .Modal__content {
    --max-width: 600px !important;
  }
}

.widgetsModal {
  .Modal__content {
    --max-width: 1000px !important;
  }
}

.card-content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  padding: 10px;

  .card {
    flex: 1 1 calc(33.33% - 20px); /* Three cards per row */
    border-radius: 7px;
    box-sizing: border-box;
    text-align: center;
    color: #fff;
    padding: 20px;
    position: relative;
    transition: background-color 0.5s;
    box-shadow: 10px 10px 5px rgb(171 171 171 / 11%);
  }

  .card-header-title {
    text-transform: uppercase;
    color: black;
    width: fit-content;
  }

  .add-button {
    background-color: #f4f4f4;
    width: 70px;
  }

  .widget {
    border-top: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
  }

  .clock_img {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 1 / 1;
  }
  /* Responsive design */
  @media screen and (max-width: 768px) {
    .card {
      flex: 1 1 calc(50% - 20px); /* Two cards per row for tablets */
    }
  }

  @media screen and (max-width: 480px) {
    .card-content {
      gap: 5px;
      padding: 5px;
    }

    .card {
      flex: 1 1 calc(100% - 20px); /* Single card per row for smaller screens */
      padding: 15px;
    }

    .add-button {
      width: 60px;
    }
  }
}
.name_picker {
  .user_profile {
    border-radius: 1rem;
    width: 2rem;
    height: 2rem;
    margin: 0 1rem;
    text-align: center;
    left: 2rem;
    line-height: 2rem;
    background-color: #c2c2c2 !important;
  }

  .search-wrapper {
    margin-bottom: 1.5rem;
  }

  .search-container {
    position: relative;
  }

  .search-input {
    width: 100%;
    padding: 12px 48px 12px 16px;
    border: none;
    border-radius: 12px;
    font-size: 0.95rem;
    transition: all 0.3s ease;
    background-color: #f9fafb;
  }

  .search-container .search-input:focus {
    box-shadow: 0 0 0 2px #efefef;
    outline: none !important;
  }

  .search-icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: #cbcbcb;
  }

  .select-all-container {
    margin-bottom: 1rem;
    padding: 0.5rem 0;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
  }

  .select-all-text {
    font-weight: 500;
    color: var(--text-primary);
  }

  .students-list {
    margin: 1rem 0;
  }

  .student-card {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    border-radius: 12px;
    margin-bottom: 0.5rem;
    transition: all 0.2s ease;
  }

  .student-card:hover {
    background-color: #f9fafb;
    transform: translateY(-1px);
  }

  .avatar {
    width: 40px;
    height: 40px;
    background: var(--primary-color);
    color: white;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin: 0 1rem;
    font-size: 1.1rem;
  }

  .student-info {
    flex: 1;
  }

  .student-name {
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 0.25rem;
  }

  .student-email {
    font-size: 0.875rem;
    color: var(--text-secondary);
  }

  .generate-btn {
    width: 100%;
    padding: 10px !important;
    border: none;
    border-radius: 12px !important;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    transition: all 0.2s ease;
    font-size: 0.95rem;
  }

  .generate-btn:hover {
    background-color: var(--button-gray-2);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
  }

  .generate-btn:active {
    transform: translateY(0);
  }

  /* Add smooth animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .student-card {
    padding: 12px 16px;
    animation: fadeIn 0.3s ease forwards;
  }

  .header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f3f3f3;
    border-radius: 12px;
    padding: 5px 7px;
  }

  .select-all {
    padding: 6px 0px;
    border-radius: 8px;
    transition: all 0.2s ease;
  }

  .select-all:hover {
    background-color: #f3f4f6;
  }

  .selected-count {
    font-size: 0.875rem;
    color: var(--text-secondary);
    font-weight: 500;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
    user-select: none;
  }

  .select-all-text {
    font-weight: 500;
    color: var(--text-primary);
  }
}
