@import "../css/variables.module";

.acv {
  .color-picker {
    background: var(--popup-bg-color);
    border: 0 solid transparentize($oc-white, 0.75);
    box-shadow: transparentize($oc-black, 0.75) 0 1px 4px;
    border-radius: 4px;
    position: absolute;
    top: 107%;

    :root[dir="ltr"] & {
      left: -5.5px;
    }

    :root[dir="rtl"] & {
      right: -5.5px;
    }
  }

  .color-picker-control-container {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }

  .color-picker-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 10px;
    border-color: transparent transparent var(--popup-bg-color);
    position: absolute;
    top: -10px;

    :root[dir="ltr"] & {
      left: 12px;
    }

    :root[dir="rtl"] & {
      right: 12px;
    }
  }

  .color-picker-triangle-shadow {
    border-color: transparent transparent transparentize($oc-black, 0.9);
    top: -11px;
  }

  .color-picker-content {
    padding: 0.5rem;
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 0.5rem;
    border-radius: 4px;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px var(--focus-highlight-color);
    }
  }

  .color-picker-content .color-input-container {
    grid-column: 1 / span 5;
  }

  .color-picker-swatch {
    position: relative;
    height: 1.875rem;
    width: 1.875rem;
    cursor: pointer;
    border-radius: 4px;
    margin: 0;
    box-sizing: border-box;
    border: 1px solid #ddd;
    background-color: currentColor !important;
    filter: var(--theme-filter);

    &:focus {
      /* TODO: only show the border when the color is too light to see as a shadow */
      box-shadow: 0 0 4px 1px currentColor;
      border-color: var(--select-highlight-color);
    }
  }

  .color-picker-transparent {
    border-radius: 4px;
    box-shadow: transparentize($oc-black, 0.9) 0 0 0 1px inset;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .color-picker-transparent,
  .color-picker-label-swatch {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==")
      left center;
  }

  .color-picker-hash {
    background: var(--input-border-color);
    height: 1.875rem;
    width: 1.875rem;

    :root[dir="ltr"] & {
      border-radius: 4px 0 0 4px;
    }

    :root[dir="rtl"] & {
      border-radius: 0 4px 4px 0;
    }

    color: var(--input-label-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .color-input-container:focus-within .color-picker-hash {
    box-shadow: 0 0 0 2px var(--focus-highlight-color);
  }

  .color-input-container:focus-within .color-picker-hash::before,
  .color-input-container:focus-within .color-picker-hash::after {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
  }

  .color-input-container:focus-within .color-picker-hash::before {
    background: var(--input-border-color);

    :root[dir="ltr"] & {
      right: -1px;
    }

    :root[dir="rtl"] & {
      left: -1px;
    }
  }

  .color-input-container:focus-within .color-picker-hash::after {
    background: var(--input-bg-color);

    :root[dir="ltr"] & {
      right: -2px;
    }

    :root[dir="rtl"] & {
      left: -2px;
    }
  }

  .color-input-container {
    display: flex;
  }

  .color-picker-input {
    width: 17.2ch; /* length of `transparent` */
    margin: 0;
    font-size: 1rem;
    background-color: var(--input-bg-color);
    color: var(--text-primary-color);
    border: 0;
    outline: none;
    height: 1.75em;
    // box-shadow: var(--input-border-color) 0 0 0 1px inset;

    :root[dir="ltr"] & {
      border-radius: 0 4px 4px 0;
    }

    :root[dir="rtl"] & {
      border-radius: 4px 0 0 4px;
    }

    float: left;
    padding: 1px;
    padding-inline-start: 0.5em;
    appearance: none;
  }

  .color-picker-label-swatch {
    height: 1.875rem;
    width: 1.875rem;
    margin-inline-end: 0.25rem;
    border: 1px solid $oc-gray-3;
    position: relative;
    overflow: hidden;
    background-color: transparent !important;
    filter: var(--theme-filter);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--swatch-color);
    }
  }

  .color-picker-keybinding {
    position: absolute;
    bottom: 2px;
    font-size: 0.7em;

    :root[dir="ltr"] & {
      right: 2px;
    }

    :root[dir="rtl"] & {
      left: 2px;
    }

    @include isMobile {
      display: none;
    }
  }

  .color-picker-type-canvasBackground .color-picker-keybinding {
    color: #aaa;
  }

  .color-picker-type-elementBackground .color-picker-keybinding {
    color: $oc-white;
  }

  .color-picker-swatch[aria-label="transparent"] .color-picker-keybinding {
    color: #aaa;
  }

  .color-picker-type-elementStroke .color-picker-keybinding {
    color: #d4d4d4;
  }

  &.theme--dark {
    .color-picker-type-elementBackground .color-picker-keybinding {
      color: $oc-black;
    }
    .color-picker-swatch[aria-label="transparent"] .color-picker-keybinding {
      color: $oc-black;
    }
  }
}
