.collection-card-img {
  height: 100%;
  width: 100%;

  div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    overflow: auto;

    img,
    svg {
      height: 100%;
      width: auto;
      object-fit: cover;
    }
    .container,
    .container > .slideshow,
    .container > .slideshow > .slideshow {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f5f5f5;
      overflow: auto;
    }
  }
}
.acv-previewDialog {
  width: 100%;
  height: 350px;
}

.checkbox_exportCanvas {
  margin: 8px;
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 1000;
  right: 94%;
  display: flex;
  justify-content: center;
}

.export-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.exportButton {
  display: flex;
  justify-content: center;
  gap: 6px;
  align-items: center;
}
.use-template-btn {
  padding: 10px 20px !important; /* Adjust padding as needed */
  font-size: 18px; /* Adjust font size as needed */
  background-color: #585a96 !important; /* Background color */
  color: #ffffff !important; /* Text color */
  border: none; /* Remove border */
  border-radius: 12px !important; /* Add rounded corners */
  cursor: pointer;
  &:hover {
    background-color: #6a6cb7 !important;
  }
  margin-top: -10px !important;
}

input[type="checkbox"]:checked {
  accent-color: #585a96 !important;
  color: #ffffff !important;
}

.show_more_button {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #585a96 !important;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 6px;
}
