.minimize-spinner {
  position: fixed;
  top: 20px;
  right: 86px;
}

.minimize-spinner .spinner {
  position: relative;
  width: fit-content;
}

.minimize-spinner .spinner .minimizeIcon {
  position: absolute;
  bottom: 2px;
  right: 4px;
}

.minimize-spinner .spinner .minimizeIcon .icon {
  background-color: #dadadaed;
}

@media screen and (max-width: 1299px) {
  .minimize-spinner {
    top: 90px;
  }
}
